import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ncrbService } from 'src/app/service/ncrb.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AdminComponent>, private service: ncrbService,
    private formBuilder: FormBuilder,@Inject(MAT_DIALOG_DATA) private data: any,) { }
  role;
  userId;
  addUserForm: FormGroup;
  addRoleForm: FormGroup;
  changePassForm:FormGroup;
  changePassForUserForm:FormGroup;
  modal;
  featureData=[];
  maxRole=0;
  loggedInUser={};

  ngOnInit(): void {
    this.userId=this.data.userId;
    this.modal=this.data.type;
    this.addUserForm = this.formBuilder.group({
      emailId: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', Validators.required],
      role:['', Validators.required]
    });
    this.addRoleForm = this.formBuilder.group({
      role: ['', Validators.required],
      features: new FormArray([])
    })
    this.changePassForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      password: ['', Validators.required],
      cnfrmPass: ['', Validators.required],
    })
    this.changePassForUserForm = this.formBuilder.group({
      password: ['', Validators.required]
    })
    this.getRole();
    this.getFeature();
    this.getRoleFeature();
    this.loggedInUser=JSON.parse(sessionStorage.getItem('loggedinUser'));
    
    if(this.userId !=="0"){
      this.service.getUserById(JSON.stringify({'userId':this.userId})).subscribe((response) => {
       
        if(response.status){
          let user=response.res[0];
          this.addUserForm.controls['emailId'].setValue(user.user_Id);
          this.addUserForm.controls['firstName'].setValue(user.first_Name);
          this.addUserForm.controls['lastName'].setValue(user.last_Name);
         // this.addUserForm.controls['password'].setValue(user.user_Id);
          this.addUserForm.controls['role'].setValue(user.user_Role);
              }
        else{
        }
      }, error => {
       // this.fetchingData1=false;
       
      })
    }
  }
  closeDialog(){
    this.dialogRef.close({})
  }

  getRole() {
    this.service.fetchRole().subscribe((response) => {
     
      if(response.status){
        this.role=response.res;
            }
      else{
      }
    }, error => {
     // this.fetchingData1=false;
     
    })
  }

  getRoleFeature(){
    this.service.getRoleFeature().subscribe((response) => {
     
      if(response.status){
     
        this.maxRole=parseInt(response.res[0].max_role);
            }
      else{
      }
    }, error => {
     // this.fetchingData1=false;
      
    })
  }

  submitUser(){
   
    if(this.userId =="0"){
      if(this.addUserForm.valid){
    let userData=JSON.stringify({
      'emailId':this.addUserForm.controls['emailId'].value,
      'firstName':this.addUserForm.controls['firstName'].value,
      'lastName':this.addUserForm.controls['lastName'].value,
      'password':this.addUserForm.controls['password'].value,
      'role':this.addUserForm.controls['role'].value,
    });
   
    this.service.saveUser(userData).subscribe((response) => {
      
      if(response.status){
        this.closeDialog();
            }
      else{
      }
    }, error => {
     // this.fetchingData1=false;
   
    });
  }else{
    alert("Please fill all the fields");
  }
  }
  else{
    let userData=JSON.stringify({
      'emailId':this.addUserForm.controls['emailId'].value,
      'firstName':this.addUserForm.controls['firstName'].value,
      'lastName':this.addUserForm.controls['lastName'].value,
      'password':this.addUserForm.controls['password'].value,
      'role':this.addUserForm.controls['role'].value,
    });
  
    this.service.updateUser(userData).subscribe((response) => {
      
      if(response.status){
        this.closeDialog();
            }
      else{
      }
    }, error => {
     // this.fetchingData1=false;
     
    });
  }

  }
  deleteUser(){
    this.service.deleteUser(JSON.stringify({'userId':this.userId})).subscribe((response) => {
      
      if(response.status){
        this.closeDialog();
            }
      else{
      }
    }, error => {
     // this.fetchingData1=false;
      
    });
  }

  getFeature() {
    this.service.fetchFeatureData().subscribe((response) => {
      
      if(response.status){
        this.featureData=response.res;
        this.addCheckboxes();
      }
      else{
      }
    }, error => {
     // this.fetchingData1=false;
      
    })
  }
  private addCheckboxes() {
    this.featureData.forEach(() => this.ordersFormArray.push(new FormControl(false)));
  }
  get ordersFormArray() {
    return this.addRoleForm.controls.features as FormArray;
  }
  submitRole() {
    if(this.addRoleForm.valid){
    this.service.saveRole(JSON.stringify({ 'role_Name': this.addRoleForm.controls['role'].value })).subscribe((response) => {
     
   if(response.status){
     let role_idd=response.res.insertId;
    const selectedOrderIds =[];
     this.addRoleForm.value.features
      .forEach((checked, i) => {
        if (checked) {
          let obj=[];
          obj.push(role_idd);
          obj.push(this.featureData[i].feature_Id);
          obj.push('Y');
          selectedOrderIds.push(obj);
        }
        else {
          let obj=[];
          obj.push(role_idd);
          obj.push(this.featureData[i].feature_Id);
          obj.push('N');
          selectedOrderIds.push(obj);
        }
      });
    this.service.saveRoleAndRoleFeature(JSON.stringify(selectedOrderIds)).subscribe((response) => {
    
      if (response.status) {
        this.closeDialog();
      }
      else {
      }
    }, error => {
      // this.fetchingData1=false;
     
    })
  }
  else{

  }
  }, error => {
    // this.fetchingData1=false;
   
    if(error.status===401){
      alert(error.error.res);
    }
  });
    }else{
      alert("Please fill role name ");
    }
  }
  
  submitPassword(){
    if(this.changePassForm.valid){
    let user=this.loggedInUser;
    let formData=this.changePassForm.value;
    this.service.checkPassword(JSON.stringify({'user_password':user['password'],'old_password':formData['oldPassword'] })).subscribe((response) => {
    
      if(response.status){
        //this.closeDialog();
        if(formData['password'] !==formData['cnfrmPass']){
          alert("New Password and Cofirm Password does not match");
          return;
        }
        else{
          this.service.resetPassword(JSON.stringify({'user_Id':user['user_Id'],'password':formData['password'] })).subscribe((response) => {
      
      if(response.status){
        this.closeDialog();
      }
      else{
      }
    }, error => {
     // this.fetchingData1=false;
      
    })
        }
      }
      else{
        alert("Old Password is Incorrect");
      }
    }, error => {
     // this.fetchingData1=false;
     
    })
  }
  else{
    alert("Please fill all fields");
  }
  
  }
  changesPassword(){
    if(this.changePassForUserForm.valid){
      this.service.resetPasswordOfUser(JSON.stringify({'userId':this.userId,'password':this.changePassForUserForm.controls['password'].value})).subscribe((response) => {
        
        if(response.status){
          this.closeDialog();
              }
        else{
        }
      }, error => {
       // this.fetchingData1=false;
       
      });
    }
    else{
      alert("Please Enter Password");
    }

  }
}
