import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { NcrbComponent } from './ncrb/ncrb.component';


const routes: Routes = [
  { path: 'ncrb', component: HomeComponent ,
  },
  { path: '',component : NcrbComponent},
  // { path: '', redirectTo: 'pps', pathMatch: 'full' },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor(){
    console.log("routes called")
  }
}
