<div class="titleClassDialog" *ngIf="!forgetPasswordScreen">
    Login
    <span class="material-icons crossIconInTitleDialog" (click)="closeDialog()">highlight_off</span>
    </div>
    <div class="titleClassDialog" *ngIf="forgetPasswordScreen">
    Recover your Account
    <span class="material-icons crossIconInTitleDialog" (click)="closeDialog()">highlight_off
    </span></div>
    
    <form [formGroup]="loginForm" style="margin: 3%;" *ngIf="!forgetPasswordScreen">
    
    <div class="col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1 col-xs-12 col-xs-offset-0">
    <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Username</mat-label>
    <input matInput placeholder="Email" type="text" (keyup)="loginFormChanged()" formControlName="userName" required>
    </mat-form-field>
    </div>
    
    <div class="col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1 col-xs-12 col-xs-offset-0">
    <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Password</mat-label>
    <input matInput placeholder="Password"[type]="hide ? 'password' : 'text'" formControlName="password" (keyup)="loginFormChanged()" required onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" autocomplete=off onmousedown='return false' onselectstart='return false'>
    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
    </div>
    <!-- <div class="col-md-12 col-lg-12 col-xs-12" style="padding: 0px;" style="text-align: center;padding: 0%;">
    <re-captcha id="captcha" class="g-recaptcha" (resolved)="resolved($event)" siteKey="6Ldc0_0ZAAAAAN4qTSxCfP4i1tOOkNXLV_FCPbON">
    </re-captcha>
    </div> -->
    
    <div class="col-md-8 col-lg-8 col-xs-10 col-md-offset-2 col-lg-offset-2 col-xs-offset-1" style="padding: 0%;">
    <button class="commonButtonColor buttonsInLogin" (click)="login()" style="float: left;">Login</button></div>
    <div class="col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2 col-xs-10 col-xs-offset-1" style="padding: 0%;text-align: end;margin-bottom: 4%;">
    <a style="cursor: pointer;" (click)="[forgetPasswordScreen=true,errorMessage='']" *ngIf="forgetPasswordScreen">Forgot your password ?</a></div>
    </form>
    <form [formGroup]="forgetPasswordForm" style="margin: 3%;" *ngIf="forgetPasswordScreen">
    <div class="col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1 col-xs-12 col-xs-offset-0" style="margin-bottom: 4%;">
    Please enter your email address. We will send you an email to reset your password.
    </div>
    <div class="col-md-10 col-lg-10 col-md-offset-1 col-lg-offset-1 col-xs-12 col-xs-offset-0">
    <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Email</mat-label>
    <input matInput placeholder="Email" type="text" formControlName="userName" required>
    </mat-form-field>
    </div>
    
    <div class="col-md-4 col-lg-4 col-xs-5 col-md-offset-2 col-lg-offset-2 col-xs-offset-1" style="margin-bottom: 4%;">
    <button class="commonButtonColorBack buttonsInLogin" (click)="backToLogin()" >Back</button></div>
    <div class="col-md-4 col-lg-4 col-xs-5 " style="margin-bottom: 4%;">
    <button class="commonButtonColor buttonsInLogin" >Submit</button></div>
    
    </form>
    
    <div *ngIf="submittingData && !forgetPasswordScreen" class="col-md-12 col-lg-12 col-xs-12 messages"><i class="fa fa-spinner fa-spin loaderIcon" ></i><span class="messagesText">Validating Details</span></div>
    <div *ngIf="submittingData && forgetPasswordScreen" class="col-md-12 col-lg-12 col-xs-12 messages"><i class="fa fa-spinner fa-spin loaderIcon" ></i><span class="messagesText">Submitting Data</span></div>
    <div *ngIf="validatingCaptcha && !submittingData" class="col-md-12 col-lg-12 col-xs-12 messages"><i class="fa fa-spinner fa-spin loaderIcon" ></i><span class="messagesText">Validating Captcha</span></div>
    <div *ngIf="errorMessage && !submittingData" class="col-md-12 col-lg-12 col-xs-12 messages errorMsg" >
    {{errorMessage}}
    </div>
    <div *ngIf="successMessage && !submittingData" class="col-md-12 col-lg-12 col-xs-12 messages" >
    {{successMessage}}
    </div>