import { Injectable, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders , HttpErrorResponse} from '@angular/common/http';
import { Observable, of ,throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { Globals } from '../globals';
  

@Injectable({
  providedIn: 'root'
})
export class ncrbService implements OnInit{
  
urlNode:any;
constructor(private http: HttpClient,public globals:Globals) {
  this.urlNode=this.globals.urlNode;
 }
ngOnInit(): void{
}
/* --------------------login & captcha-------------------- */
login(formdata:any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  let body = JSON.stringify({
    "userID":formdata.userName,
    "password":formdata.password
  });
  return this.http.post<any>(this.urlNode+'/login',body,httpOptions);
}
captcha(captcha:any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  let body = JSON.stringify({
    "captcha":captcha,
    
  })
  return this.http.post<any>(this.urlNode+'/captcha',body,httpOptions);
}
/* --------------------FIR Analytics------------------------- */
fetchFirData(): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  
  return this.http.post<any>(this.urlNode+'/fetchFirData',httpOptions);
}
/* --------------------Classify FIR------------------------- */
classifyFIRStoreFile(formData: any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    })
  };
  return this.http.post<any>(this.urlNode+'/classifyFIRStoreFile',formData,httpOptions).pipe(
    catchError(this.handleError)
    );
}

getUserFeatures(role_id){
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  let body = JSON.stringify({
    "role_id":role_id
  })
  
  return this.http.post<any>(this.urlNode+'/getFeatures',body,httpOptions);
}

classifyFirModel(loc:any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  let body = JSON.stringify({
    "loc":loc,
    "text":null,
    "act":null,
    "section":null,
    "language_code":null,
  })

  return this.http.post<any>(this.urlNode+'/classifyFirModel',body,httpOptions);
}
/* --------------------Model Trainer------------------------- */
fetchModelData(chnageMode:any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  let body = JSON.stringify({
    "changeMode":chnageMode,
  })
  return this.http.post<any>(this.urlNode+'/fetchModelData',body,httpOptions);
}
changeDefaultModel(modelID:any,languageCode:any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  let body = JSON.stringify({
    "modelID":modelID,
    "language_Code":languageCode,
  })
  
  return this.http.post<any>(this.urlNode+'/changeDefaultModel',body,httpOptions);
}
changeAccess(data: any,data1: any,data2:any) {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  let body = JSON.stringify({
    "role_Id":data,
    "feature_Id":data1,
    "access_Allowed":data2,
  })
  
  return this.http.post<any>(this.urlNode+'/changeAccess',body,httpOptions);
}

  handleError(error: HttpErrorResponse){
    
    return throwError(error);
    }

    loggedIn(){
      return !!localStorage.getItem('token');
    }
    //State
    fetchStateData(): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type':'application/json'
        })
      };
      return this.http.get<any>(this.urlNode+'/fetchStateData',httpOptions);
    }
    //Feature
    fetchFeatureData(): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type':'application/json'
        })
      };
      return this.http.get<any>(this.urlNode+'/fetchFeatureData',httpOptions);  
    }
//    fetchMajorHead

  fetchMajorHead(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type':'application/json'
      })
    };
    return this.http.get<any>(this.urlNode+'/fetchMajorHead',httpOptions);  
  }
  //    fetchMinorHead

  fetchMinorHead(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type':'application/json'
      })
    };
    return this.http.get<any>(this.urlNode+'/fetchMinorHead',httpOptions);  
  }   
//    fetchAllUsers

fetchAllUsers(): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  return this.http.get<any>(this.urlNode+'/fetchAllUsers',httpOptions);  
}
//    fetchRoleFeature

// fetchRoleFeature(formData: any): Observable<any> {
//   const httpOptions = {
//     headers: new HttpHeaders({
//       'Access-Control-Allow-Origin': '*',
//       'Content-Type':'application/json'
//     })
//   };
//   return this.http.get<any>(this.urlNode+'/fetchRoleFeature',formData,httpOptions);  
// }  
//    fetchRole

fetchRole(): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  return this.http.get<any>(this.urlNode+'/fetchRole',httpOptions);  
}  
fetchRoleFeature(role_Id: any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  let body = JSON.stringify({
    "role_Id":role_Id,
  })
  return this.http.post<any>(this.urlNode+'/fetchRoleFeature',body,httpOptions);
}

fetchAllLanguages(): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  return this.http.get<any>(this.urlNode+'/fetchAllLanguages',httpOptions);  
}
fetchClassifyr(selectFirContent: any, selectAct: any, selectedSection: any, selectedLanguages: any) {



  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  let body = JSON.stringify({
    "text":selectFirContent,
    "act":selectAct,
    "section":selectedSection,
    "language_code":selectedLanguages,
  })
  return this.http.post<any>(this.urlNode+'/fetchClassifyr',body,httpOptions);

  //return this.http.post<any>('http://122.160.79.199:8000/prediction/',body,httpOptions);

 
  
}

fetchReport(): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  return this.http.get<any>(this.urlNode+'/fetchReport',httpOptions);
}
fetchReportAct(data:any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  let body = JSON.stringify({
    "data":data,
  })
  return this.http.post<any>(this.urlNode+'/fetchReportAct',body,httpOptions);
}
fetchAct(): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
  return this.http.get<any>(this.urlNode+'/fetchAct',httpOptions);
}



saveUser(body: any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
 
  return this.http.post<any>(this.urlNode+'/saveUser',body,httpOptions);
}

getUserById(body: any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
 
  return this.http.post<any>(this.urlNode+'/getUserById',body,httpOptions);
}

updateUser(body: any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
 
  return this.http.post<any>(this.urlNode+'/updateUser',body,httpOptions);
}

deleteUser(body: any): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
 
  return this.http.post<any>(this.urlNode+'/deleteUser',body,httpOptions);
}

getRoleFeature(): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type':'application/json'
    })
  };
 
  return this.http.get<any>(this.urlNode+'/getRoleFeature',httpOptions);
  }

  saveRoleAndRoleFeature(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type':'application/json'
      })
    };
  
   
    return this.http.post<any>(this.urlNode+'/saveRoleAndRoleFeature',body,httpOptions);
  }
  resetPassword(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type':'application/json'
      })
    };
  
    return this.http.post<any>(this.urlNode+'/resetPassword',body,httpOptions);
  }

  checkPassword(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type':'application/json'
      })
    };
  
    return this.http.post<any>(this.urlNode+'/checkPassword',body,httpOptions);
  }


  deleteModelData(modelID: any): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type':'application/json'
      })
    };
    let body = JSON.stringify({
      "modelID":modelID,
    })
   
    return this.http.post<any>(this.urlNode+'/deleteModelData',body,httpOptions);
  }

  
  

  saveRole(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type':'application/json'
      })
    };
    return this.http.post<any>(this.urlNode+'/saveRole',body,httpOptions);
  }



  resetPasswordOfUser(body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type':'application/json'
      })
    };
  
    return this.http.post<any>(this.urlNode+'/resetPasswordOfUser',body,httpOptions);
  }


}

// }
// //    fetchRoleFeature

// // fetchRoleFeature(formData: any): Observable<any> {
// //   const httpOptions = {
// //     headers: new HttpHeaders({
// //       'Access-Control-Allow-Origin': '*',
// //       'Content-Type':'application/json'
// //     })
// //   };
// //   return this.http.get<any>(this.urlNode+'/fetchRoleFeature',formData,httpOptions);  
// // }  
// //    fetchRole

// fetchRole(): Observable<any> {
//   const httpOptions = {
//     headers: new HttpHeaders({
//       'Access-Control-Allow-Origin': '*',
//       'Content-Type':'application/json'
//     })
//   };
//   return this.http.get<any>(this.urlNode+'/fetchRole',httpOptions);  
// }  
// fetchRoleFeature(role_Id: any): Observable<any> {
//   const httpOptions = {
//     headers: new HttpHeaders({
//       'Access-Control-Allow-Origin': '*',
//       'Content-Type':'application/json'
//     })
//   };
//   let body = JSON.stringify({
//     "role_Id":role_Id,
//   })
//   return this.http.post<any>(this.urlNode+'/fetchRoleFeature',body,httpOptions);
// }}

