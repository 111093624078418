import { Component, OnInit} from '@angular/core';
import { ncrbService } from 'src/app/service/ncrb.service';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AdminComponent } from '../admin/admin.component';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],

})
export class HomeComponent implements OnInit {
  /* general */
  fileName= 'ExcelSheet.xlsx';
  errorMessage:string='';
  fetchingData1:boolean=false
  fetchingData: boolean = false;
  activeLink: string = '';
  docs: any;
  selectAt:any;
  loggedInUser: any = [];
  changeAccessAction:any; 
  clickAct='All'; 
  fileSelected: boolean = false;
  errorCase:boolean=false;
  errorCase1:boolean=false;
  lang:string='eng';
  overAllCrimeSum1:number=0;
  overAllCrimeSum2:number=0;
  overAllCrimeSum3:number=0;
  fromDate;
  toDate;
  features=[];
  isAccessOfAnalytics=false;
  isAccessOfClassifier=false;
  isAccessOfTrainer=false;
  isAccessReports=false;
  isAccessAdmin=false;
  /* firAnalytics */
  stateUTSelect: {}[] ;
  featureNameArray=[];
  selectedRole:any;
  allLanguages: {}[] ;
  defaultDate=new Date();
  date1:number=0;
  date2=0;
  date3=0;
  language=[
    {actual: 'Haryana', value: 'haryana'},
    {actual: 'Punjab', value: 'punjab'},
    {actual: 'Maharashtra', value: 'maharashtra'},
    {actual:'All',value:'all'}
  ];

  districtSelect=[
    {actual:'All',value:'all'}
  ];
  ipcsslSelect=[
    {actual: 'IPC', value: 'ipc'},
    {actual: 'SSL', value: 'ssl'},
    {actual:'All',value:'All'}
  ];
  // groupSelect=[
  //   {actual: 'Offences Affecting the Human Body', value: 'Offences Affecting the Human Body'},
  //   {actual: 'Miscellaneous IPC Crimes', value: 'Miscellaneous IPC Crimes'},
  //   {actual: 'Liquor & Narcotic Drugs – Related Acts', value: 'Liquor & Narcotic Drugs – Related Acts'},
  //   {actual:'All',value:'all'}
  // ];
  // categorySelect=[
  //   {actual: 'Hurt', value: 'Hurt'},
  //   {actual: 'Rash Driving on Public way', value: 'Rash Driving on Public way'},
  //   {actual: 'The Excise Act', value: 'The Excise Act'},
  //   {actual:'All',value:'all'}
  // ];
  // subcatSelect=[
  //   {actual: 'Simple Hurt', value: 'Simple Hurt'},
  //   {actual: 'Causing Hurt by Rash/Negligent Driving on Public Way', value: 'Causing Hurt by Rash/Negligent Driving on Public Way'},
  //   {actual: 'Causing Grievous Hurt by Rash/Negligent Driving on Public', value: 'Causing Grievous Hurt by Rash/Negligent Driving on Public'},
  //   {actual:'All',value:'all'}
  // ];
  // subsubcatSelect=[
  //   {actual: 'Voluntarily Causing Simple Hurt', value: 'Voluntarily Causing Simple Hurt'},
  //   {actual: 'Voluntarily causing hurt by Dangerous weapons or means', value: 'Voluntarily causing hurt by Dangerous weapons or means'},
  //   {actual:'All',value:'all'}
  // ];


 
  selectedLanguages: any;
  selectFirNo:any;
  selectFirContent:any;
  selectAct: any;
  selectedSection: any;
  messageInRole="";
  selectedStateDistrict: string='All';//='all';
  selectedState: string='All';

  selectedIPC_SSl='All';
  selectedGroup='all';
  selectedCat='All';
  selectedSubcat='All';
  selectedSubsubcat='all';
  changeModel1: any;
  firAnalyticsTableData=[];
  messageInFirAnalytics:string='Click on Analyze to view data'
  majorHeadFilter="All";
  /* reports */
  selectedReportOpt: string = "Act & Sections";
  reportsData=[
    {date:'07-Dec-2020 11:13:33',user:'pkumar@ncrb@gov.in',totalFirs:14237,classifiedFirs:12129},
    {date:'08-Dec-2020 12:12:33',user:'rsingh@ncrb@gov.in',totalFirs:15255,classifiedFirs:15232}]
  /* admin */
  selectedAdminOpt:string = 'Users';
   admin1: {}[] ;
   act1: {}[] ;
   //=[
  //   {user:'rsingh@ncrb@gov.in yyyyyyyyyyyyyyyyyyyyyyyyyyy',firstName:'Ramesh',lastName:'Singh',role:'Admin'},
  //   {user:'pkumar@ncrb@gov.in',firstName:'Prakash',lastName:'Kumar',role:'Data Scientist'},
  //   {user:'cgupta@ncrb@gov.in',firstName:'Charan',lastName:'Gupta',role:'Crime Analyst'},
  //   {user:'mrana@ncrb@gov.in',firstName:'Manish',lastName:'Rana',role:'Crime Analyst'},
  //   {user:'rsingh@ncrb@gov.in yyyyyyyyyyyyyyyyyyyyyyyyyyy',firstName:'Ramesh',lastName:'Singh',role:'Admin'},
  //   {user:'pkumar@ncrb@gov.in',firstName:'Prakash',lastName:'Kumar',role:'Data Scientist'},
  //   {user:'cgupta@ncrb@gov.in',firstName:'Charan',lastName:'Gupta',role:'Crime Analyst'},
  //   {user:'mrana@ncrb@gov.in',firstName:'Manish',lastName:'Rana',role:'Crime Analyst'},{user:'rsingh@ncrb@gov.in yyyyyyyyyyyyyyyyyyyyyyyyyyy',firstName:'Ramesh',lastName:'Singh',role:'Admin'},
  //   {user:'pkumar@ncrb@gov.in',firstName:'Prakash',lastName:'Kumar',role:'Data Scientist'},
  //   {user:'cgupta@ncrb@gov.in',firstName:'Charan',lastName:'Gupta',role:'Crime Analyst'},
  //   {user:'mrana@ncrb@gov.in',firstName:'Manish',lastName:'Rana',role:'Crime Analyst'},{user:'rsingh@ncrb@gov.in yyyyyyyyyyyyyyyyyyyyyyyyyyy',firstName:'Ramesh',lastName:'Singh',role:'Admin'},
  //   {user:'pkumar@ncrb@gov.in',firstName:'Prakash',lastName:'Kumar',role:'Data Scientist'},
  //   {user:'cgupta@ncrb@gov.in',firstName:'Charan',lastName:'Gupta',role:'Crime Analyst'},
  //   {user:'mrana@ncrb@gov.in',firstName:'Manish',lastName:'Rana',role:'Crime Analyst'},{user:'rsingh@ncrb@gov.in yyyyyyyyyyyyyyyyyyyyyyyyyyy',firstName:'Ramesh',lastName:'Singh',role:'Admin'},
  //   {user:'pkumar@ncrb@gov.in',firstName:'Prakash',lastName:'Kumar',role:'Data Scientist'},
  //   {user:'cgupta@ncrb@gov.in',firstName:'Charan',lastName:'Gupta',role:'Crime Analyst'},
  //   {user:'mrana@ncrb@gov.in',firstName:'Manish',lastName:'Rana',role:'Crime Analyst'},
  // ];
  // adminCrimeDictionary:{}[]=[
  //   {word:'Abduction',synonym:'Taking someone away by force,demanding money for their safe return'},
  //   {word:'Arson',synonym:'Setting fire to a building, cars or property on purpose'},
  //   {word:'Assassination',synonym:'Killing a famous person or public figure'},
  //   {word:'Assault',synonym:'Attacking someone physically'},
  //   {word:'Bigamy',synonym:'Marrying someone when you are already married to another person'},
  //   {word:'Blackmail',synonym:'Threatening to reveal someone’s secrets if a lot of money is not paid'},
  //   {word:'Bombing',synonym:'Detonating an explosive device with the plan of harming people or property'},
  //   {word:'Bribery',synonym:'Giving money or granting favors to influence another person’s decisions or behavior'},
  //   {word:'Burglary',synonym:'Breaking into a house in order to steal something'},
  //   {word:'Child abuse',synonym:'Treating a child badly in a physical, emotional, or sexual way'},
  // ]
  /* Train the model */
  trainModelTableData: {}[] ;
  fileSubmitClickedTrainModel: boolean = false;
  
  /* FIR classifier */
  messageLine1:string='';
  messageLine3:string='';
  fileSubmitClickedFIRClassifier: boolean = false;
  featureData: {}[];
  majorHead=[];
  minorHead= [];
  allMinorHead=[];
  allUsers: {}[];
  roleFeature: {}[];
  role: {}[];
  selectRoleData: any;
   result: {}[];
//     "Major_head1": "M1",
//     "Minor_head1": "I1",
//     "Major_head2": "M2",
//     "Minor_head2": "I2",
//     "Major_head3": "M3",
//     "Minor_head3": "I3"
// },{
//   "Major_head1": "M11",
//   "Minor_head1": "I11",
//   "Major_head2": "M22",
//   "Minor_head2": "I22",
//   "Major_head3": "M33",
//   "Minor_head3": "I33"
// }];
  constructor(private router: Router,  private service: ncrbService,private dialog: MatDialog) { }
  /*  methods start */
  ngOnInit(): void {
    
    this.getState()
    this.getFeature()
    this.getMajorHead()
    this.getMinorHead()
    this.getAllUsers()
    this.getAllLanguages()
    this.getRole()
    this.date1=this.defaultDate.getFullYear();
    this.date2=this.date1-1;
    this.date3=this.date1-2;
    this.loggedInUser = JSON.parse(sessionStorage.getItem('loggedinUser'));
    
    this.service.getUserFeatures(this.loggedInUser.user_Role).subscribe((response) => {
      
     // let sortedScreen=response.res.sort((a,b)=>a.)
      this.featureNameArray= response.res.map(m=>{
        if(m.access_Allowed==='Y'){
          return m.feature_Name;
        }
      });
     
      this.activeLink=this.featureNameArray[0];
      // response.res.forEach(m=>{
      //   ////////
      
      //   ////////
      //   if(m.access_Allowed==='Y'){
      //     if(m.feature_Name.toUpperCase()==="FIR ANALYTICS")
      //     {
      //       this.isAccessOfAnalytics=true;
      //     }
      //     if(m.feature_Name.toUpperCase()==="FIR CLASSIFIER")
      //     {
      //       this.isAccessOfClassifier=true;
      //     }
      //     if(m.feature_Name.toUpperCase()==="MODEL TRAINER")
      //     {
      //       this.isAccessOfTrainer=true;
      //     }
      //     if(m.feature_Name.toUpperCase()==="REPORTS")
      //     {
      //       this.isAccessReports=true;
      //     }
      //     if(m.feature_Name.toUpperCase()==="ADMIN")
      //     {
      //       this.isAccessAdmin=true;
      //     }
      //   }
      // })
     
    })
    if(!this.isAccessOfAnalytics &&(this.isAccessOfClassifier || this.isAccessOfTrainer)){
      this.activeLink = 'firClassifier';
    }
    this.firAnalytics();  
  }
  isAccess(feature_Name){
   return this.featureNameArray.includes(feature_Name);
  }
  getRole() {
    this.service.fetchRole().subscribe((response) => {
    
      if(response.status){
        this.role=response.res;
            }
      else{
      }
    }, error => {
      this.fetchingData1=false;
     
    })
  }
  getRoleFeature() {
    this.service.fetchRoleFeature(this.selectRoleData).subscribe((response) => {
      
      if(response.status){
        this.roleFeature=response.res;
         }
      else{
      }
    }, error => {
      this.fetchingData1=false;
     
    })
  }
  getAllUsers() {
    this.service.fetchAllUsers().subscribe((response) => {
      
      if(response.status){
        this.allUsers=response.res;
      }
      else{
      }
    }, error => {
      this.fetchingData1=false;
      
    })
  }
  getMinorHead() {
    this.service.fetchMinorHead().subscribe((response) => {
      
      if(response.status){
       // this.minorHead=response.res;
        this.minorHead=response.res.sort((a, b) => a.minor_Head_Name.localeCompare(b.minor_Head_Name))
      this.allMinorHead=this.minorHead;
        // this.minorHead=this.minorHead.sort((a,b) => (a.minor_Head_Name > b.minor_Head_Name) ? 1 : -1 )
      }
      else{
      }
    }, error => {
      this.fetchingData1=false;
    
    })
  }
  getMajorHead() {
    this.service.fetchMajorHead().subscribe((response) => {
      
      if(response.status){
        //this.majorHead=response.res;
        this.majorHead=response.res.sort((a, b) => a.major_Head_Name.localeCompare(b.major_Head_Name))
      }
      else{
      }
    }, error => {
      this.fetchingData1=false;
    
    })
      }
  getFeature() {
    this.service.fetchFeatureData().subscribe((response) => {
     
      if(response.status){
       // this.featureData=response.res;
       // this.featureData=response.res.sort((a,b)=>parseInt(a.Screen_Sequence_Number)>parseInt(b.Screen_Sequence_Number));
        this.featureData=response.res.sort((a,b) => (a.Screen_Sequence_Number > b.Screen_Sequence_Number) ? 1 : ((b.Screen_Sequence_Number > a.Screen_Sequence_Number) ? -1 : 0))
      
      }
      else{
      }
    }, error => {
      this.fetchingData1=false;
     
    })
  }
  getAllLanguages() {
    this.service.fetchAllLanguages().subscribe((response) => {
    
      if(response.status){
        this.allLanguages=response.res;
      }
      else{
      }
    }, error => {
      this.fetchingData1=false;
      
    })
  }
  changeSidenavOpt(data: any) {
    if (data == 'FIR Analytics') {
      this.customResult=[];
      this.selectedState='All'
      this.selectedStateDistrict='All'
      this.selectedIPC_SSl='All'
      this.fromDate='';
      this.toDate='';
      this.selectedCat='All';
      this.minorHead=this.allMinorHead
      this.activeLink = 'FIR Analytics';
      this.firAnalytics()
      
     }
     else if (data == 'FIR Classifier'){
      this.activeLink = 'FIR Classifier';
      this.fileSubmitClickedFIRClassifier=false;
      this.docs=null;
      this.fetchingData=false;
      this.fetchingData1=false;
      this.messageLine1='';
      this.messageLine3='';
      this.selectedSection='';
      this.selectAct='';
      this.selectFirContent='';
      this.getAllLanguages()
      this.selectFirNo='';
      this.result=null;
    }
    else if (data == 'Model Trainer'){
      this.activeLink = 'Model Trainer';
      this.docs=null;
      this.errorMessage=''
      this.trainModelTableData=null;
    }
    else if (data == 'Reports') {
      this.admin1=null;
      this.act1=null;
      this.activeLink = 'Reports';
      this.report()
      this.act()
    }
    else if (data == 'Admin') {
      
      this.activeLink = 'Admin';
      this.selectedAdminOpt='Users';
      this.selectRoleData='';
      this.roleFeature=null;
      this.role=null;
      this.getRole()
      this.selectedRole='';
    }
  }
  report() {
    this.service.fetchReport().subscribe((response) => {
    
      if(response.status){
        this.admin1=response.res;
      }
      else{
      }
    }, error => {
      this.fetchingData1=false;
      
    })
  }
  report1(data){
  this.service.fetchReportAct(data).subscribe((response) => {
    
    if(response.status){
      this.admin1=response.res;
    }
    else{
    }
  }, error => {
    this.fetchingData1=false;
  
  })
}

  act() {
    this.service.fetchAct().subscribe((response) => {
   
      if(response.status){
        this.act1=response.res;
      }
      else{
      }
    }, error => {
      this.fetchingData1=false;
    
    })
  }
  getState() {
    
    this.service.fetchStateData().subscribe((response) => {
  
      if(response.status){
        this.stateUTSelect=response.res;
      }
      else{
      }
    }, error => {
      this.fetchingData1=false;
   
    })
   }
  
  logout() {
    sessionStorage.setItem('loggedinUser',null);    
    this.router.navigate(['/']);
  }
  onSelectFile(event: any) {
    this.errorMessage='';
    this.fileSubmitClickedFIRClassifier= false;
    if (event.target.files.length > 0) {
      this.docs = event.target.files;
    }
  
  }
  /* -----------------------------------FIR Analytics methods-------------------------*/
  firAnalytics(){
  this.firAnalyticsTableData=[];
  this.messageInFirAnalytics='Click on Analyze to view data for last Classification.'
  }
  firAnalyticsAnalyze1(){
    
   // if(this.firAnalyticsTableData.length==0){
    if(this.fromDate){
     
      this.date3=parseInt(this.fromDate.split('-')[0]);
      this.date2=this.date3+1;
      this.date1=this.date3+2;
    }
    else{
      if(this.toDate){
        this.date1=parseInt(this.toDate.split('-')[0]);
      this.date2=this.date1-1;
      this.date3=this.date1-2;
      }
    }
    
    this.messageInFirAnalytics='Fetching Data...'
  this.service.fetchFirData().subscribe((response) => {
    
   
    if(response.status){
    let TableData=response.res;
    TableData=TableData.filter(m=>m.major_Head1!="" && m.minor_Head1!="" )
     if(TableData.length==0){
      this.messageInFirAnalytics='No data found'
     }
     let count=0;
     var myData=[];
     let newDate1=this.date1;
    let newDate2=this.date2;
    let newDate3=this.date3;
    
     TableData=TableData.filter(m=>m.reg_date.includes(newDate1) || m.reg_date.includes(newDate2) || m.reg_date.includes(newDate3))
     
     TableData= this.getFilteredFirData(TableData);
     _.each(TableData, function(item){
      if(myData.some(data => data.major_Head1 === item.major_Head1)){
        let obj=item;
        let count=0;
        obj['sNo']=item.major_Head1+'.'+item.minor_Head1;
        obj['headName']=item.minor_Head_Name;
      
        myData.push(obj);
      }
      else{
        let obj=item;
        obj['sNo']=item.major_Head1;
        obj['headName']=item.major_Head_Name;
        myData.push({...obj});
        obj['sNo']=item.major_Head1+'.'+item.minor_Head1;
        obj['headName']=item.minor_Head_Name;
        myData.push({...obj});
      }
     });
    let anotherData=myData;
    //this.firAnalyticsTableData=myData;
    let wholeData=[];
    
   
    let overAllCrimeSumLocal1=0;
    let overAllCrimeSumLocal2=0;
    let overAllCrimeSumLocal3=0;
    anotherData=anotherData.filter((elem,index)=>
    anotherData.findIndex(obj=>obj.sNo === elem.sNo)===index
   )
   
    _.each(anotherData, function(item){
      let yearDataPerCent2018=0;
      let yearDataPerCent2019=0;
      let yearDataPerCent2020=0;
       let yearData2018=0;
       let yearData2019=0;
       let yearData2020=0;
      
      _.each(TableData, function(item1){
      
          if(item1.reg_date.includes(newDate1)){
            if(item1.major_Head_Name===item.headName || item1.minor_Head_Name===item.headName1 ){
              yearData2020=yearData2020+1;
            }
          }
          if(item.reg_date.includes(newDate2)){
          
            if(item1.major_Head_Name===item.headName || item1.minor_Head_Name===item.headName){
              yearData2019=yearData2019+1;
            }
          }
          if(item1.reg_date.includes(newDate3)){
            if(item1.major_Head_Name===item.headName || item1.minor_Head_Name===item.headName){
              yearData2018=yearData2018+1;
            }
          }
      })
      let incidenceData=item;
      
      incidenceData['yearData2018']=yearData2018;
      incidenceData['yearData2019']=yearData2019;
      incidenceData['yearData2020']=yearData2020;
      //let overallData=yearData2018+yearData2019+yearData2020;
      incidenceData['yearDataPerCent2018']=yearData2018==0?0:(yearData2018*100);
      incidenceData['yearDataPerCent2019']=yearData2019==0?0:(yearData2019*100);
      incidenceData['yearDataPerCent2020']=yearData2020==0?0:(yearData2020*100);
      //this.firAnalyticsTableData.push();
      overAllCrimeSumLocal1=overAllCrimeSumLocal1 + yearData2018;
      overAllCrimeSumLocal2=overAllCrimeSumLocal2 + yearData2019
      overAllCrimeSumLocal3=overAllCrimeSumLocal3 + yearData2020
      wholeData.push(incidenceData);
    });
    this.overAllCrimeSum1=overAllCrimeSumLocal1;
    this.overAllCrimeSum2=overAllCrimeSumLocal2;
    this.overAllCrimeSum3=overAllCrimeSumLocal3;
    
     this.firAnalyticsTableData=wholeData;

       
  
	
    
      }

  
    }, error => {
      this.messageInFirAnalytics='Some error occured.'
      
    })
  
  }
  customResult = [];
  generateCustomRows(rows: any): void{
    let result = [];
    let major_row: any = {};
    let row: any = {};
    let count: number;
    
    rows = rows
      .filter((x) => {
        return x.major_Head1 && [this.date3, this.date2, this.date1].includes(x.reg_year);
      }).sort((a, b) => (parseInt(a.major_Head1) > parseInt(b.major_Head1)) ? 1 : -1)
      
  
    for (let i = 0; i < rows.length;) {
        let major_Head=rows[i].major_Head1
        major_row = {
            code: rows[i].major_Head1,
            title: rows[i].major_Head_Name,
        }
        result.push(major_row);
        row={
            code: rows[i].major_Head1+"."+rows[i].minor_Head1,
            title: rows[i].minor_Head_Name,
        }
        row[rows[i].reg_year.toString()] = rows[i].count;
        major_row[rows[i].reg_year.toString()] = major_row[rows[i].reg_year.toString()] || 0;
        major_row[rows[i].reg_year.toString()] += rows[i].count;
        result.push(row)
        i=i+1;
        while(i<rows.length && major_Head == rows[i].major_Head1){
        
            if(result.filter(x=>{return x.code == (rows[i].major_Head1+"."+rows[i].minor_Head1)}).length ==0){
              row={
                code: rows[i].major_Head1+"."+rows[i].minor_Head1,
                title: rows[i].minor_Head_Name,
              }
              row[rows[i].reg_year.toString()] = rows[i].count;
              count = row[rows[i].reg_year.toString()];
              result.push(row);
            }else{
              var foundIndex = result.findIndex(y => y.code == (rows[i].major_Head1+"."+rows[i].minor_Head1));
              result[foundIndex][rows[i].reg_year.toString()] = rows[i].count;
              count = result[foundIndex][rows[i].reg_year.toString()];
            }
            major_row[rows[i].reg_year.toString()] = major_row[rows[i].reg_year.toString()] || 0;
            major_row[rows[i].reg_year.toString()] += count;
            i=i+1;
        }
    }
   
    let total: any = {};
    total[this.date1.toString()] = 0;
    total[this.date2.toString()] = 0;
    total[this.date3.toString()] = 0;
    result.map(x=>{
      total[this.date1.toString()] += (x[this.date1.toString()] || 0);
      total[this.date2.toString()] += (x[this.date2.toString()] || 0);
      total[this.date3.toString()] += (x[this.date3.toString()] || 0);
    })
  
    result.map(x=>{
      x[this.date1.toString()+"_rate"] = parseInt((((x[this.date1.toString()] || 0)*100)/total[this.date1.toString()]).toString());
      x[this.date2.toString()+"_rate"] = parseInt((((x[this.date2.toString()] || 0)*100)/total[this.date2.toString()]).toString());
      x[this.date3.toString()+"_rate"] = parseInt((((x[this.date3.toString()] || 0)*100)/total[this.date3.toString()]).toString());
    })
    
    // result = result
    // .sort((a, b) => (a.code <= b.code))
    // .filter(m=>m);
    result=result.sort((a,b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0));
    result.forEach(m=>{
      if(m.code.includes('.')){
        let obj=m
        obj['flag']='minor';
      }
      else{
        let obj=m
        obj['flag']='major';
      }
      this.customResult.push(m);
    })
    if(this.customResult.length<1){
      this.messageInFirAnalytics="No Data Found";
    }
    //this.customResult= result;
  }

  ///////////////////////////////

  firAnalyticsAnalyze(){
    this.customResult=[];
    // if(this.firAnalyticsTableData.length==0){
     
      

        if(this.fromDate && this.toDate){
          if(new Date(this.toDate)<new Date(this.fromDate) || new Date(this.toDate)>new Date() ){
            alert('To Date must be less than Today and Greater Than From Date');
            return;
        }
        else{
          let fromDatenew=parseInt(this.fromDate.split('-')[0]);
          let toDatenew=parseInt(this.toDate.split('-')[0]);
         
          if(fromDatenew ==toDatenew){
            this.date3=fromDatenew
            this.date2=0;
            this.date1=0;
          }
          else if(toDatenew-fromDatenew==1){
            this.date3=fromDatenew
            this.date2=this.date3+1;
            this.date1=0;
          }
          else if(toDatenew-fromDatenew>1){
            this.date3=fromDatenew
            this.date2=this.date3+1;
            this.date1=this.date3+2;
          }
        }
        }
        else  if(this.fromDate ){
          if(new Date(this.fromDate)>new Date()){
            alert('From Date Can not be greater than Today');
            return;
          }
          else{
            let currentYear=(new Date()).getFullYear();
            let ffdate=parseInt(this.fromDate.split('-')[0]);
            if(currentYear-ffdate==0){
            this.date3=ffdate;
            this.date2=0;
            this.date1=0;
            }
            else if(currentYear-ffdate==1){
              this.date3=ffdate;
              this.date2=this.date3+1;
              this.date1=0;
            }
            else if(currentYear-ffdate>=2){
              this.date3=ffdate;
              this.date2=this.date3+1;
              this.date1=this.date3+2;
            }
          }
        }
        else if(this.toDate ){
          if(new Date(this.toDate)<new Date(this.fromDate) || new Date(this.toDate)>new Date() ){
            alert('To Date must be less than Today and Greater Than From Date');
            return;
          }
          else{
            let currentYear=(new Date()).getFullYear();
            let ttYear=parseInt(this.toDate.split('-')[0]);
            if(currentYear-ttYear==0){
              this.date1=ttYear;
              this.date2=0;
              this.date3=0;
            }
            else if(currentYear-ttYear==1){
              this.date1=ttYear;
              this.date2=this.date1-1;
              this.date3=0;
            }
            else if(currentYear-ttYear>=2){
              this.date1=ttYear;
              this.date2=this.date1-1;
              this.date3=this.date1-2;
            }
          }
          }
        

      
      this.messageInFirAnalytics='Fetching Data...'
    this.service.fetchFirData().subscribe((response) => {
      
      
      if(response.status){
       
        let actualData=response.res;
        actualData=actualData.filter(m=>m.major_Head1!="" && m.minor_Head1!="" )
      
        let dataa=this.majorMinorFilter(actualData);
     
        this.generateCustomRows(dataa);
    
      }

  
    }, error => {
      this.messageInFirAnalytics='Some error occured.'
    
    })
  
  }
  exportExcel() {
    if(this.customResult.length==0){
      return;
    }
   
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('NCRB FIR Analytics');
    let fDate=this.GetFormattedDate(this.fromDate);
    let tDate=this.GetFormattedDate(this.toDate);
    worksheet.addRow([]);
    worksheet.addRow(['State/UT:', this.selectedState, 'District:', this.selectedStateDistrict, '', 'IPC/SSL:', this.selectedIPC_SSl]);
    worksheet.addRow(['From Date:', fDate , 'To Date:', tDate , ]);
    worksheet.addRow(['Major Head:', this.majorHeadFilter,'Minor Head:', this.selectedSubcat]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    // worksheet.insertRow(7, ['sadfsdf']);
    worksheet.addRow(['Major/Minor Code', 'Crime Head', this.date3.toString()=='0'?'No Data':this.date3.toString(),'', this.date2.toString()=='0'?'No Data':this.date2.toString(), '', this.date1.toString()=='0'?'No Data':this.date1.toString()]);
    worksheet.getRow(7).font = { name: 'Calibri', bold: true };
    worksheet.getRow(7).alignment = { vertical: 'middle', horizontal: 'center' };
    ['A','B','C','D','E','F','G','H'].map((x)=> {
      worksheet.getCell(x+'7').border = {
        top: {style:'hair'},
        left: {style:'hair'},
        bottom: {style:'hair'},
        right: {style:'hair'}
      };
    });
    worksheet.mergeCells('C7:D7');
    worksheet.mergeCells('E7:F7');
    worksheet.mergeCells('G7:H7');
    worksheet.addRow(['','','Incidence','Crime Rate','Incidence','Crime Rate','Incidence','Crime Rate'], 'i');
    worksheet.addRow([1,2,3,4,5,6,7,8], 'i');
    worksheet.columns = [
      { width: 20 },
      { width: 40 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];
    this.customResult.forEach((x,i) => {
      worksheet.addRow([
        x.code,
        x.title,
        x[this.date3.toString()] || 0,
        (x[this.date3.toString()+"_rate"] || 0)+'%',
        x[this.date2.toString()] || 0,
        (x[this.date2.toString()+"_rate"] || 0)+'%',
        x[this.date1.toString()] || 0,
        (x[this.date1.toString()+"_rate"] || 0)+'%',
      ],"n");
      ['A','B','C','D','E','F','G','H'].map((x)=> {
        worksheet.getCell(x+(i+10).toString()).border = {
          top: {style:'hair'},
          left: {style:'hair'},
          bottom: {style:'hair'},
          right: {style:'hair'}
        };
      });
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'NCRB FIR Analytics.xlsx');
    })
  }
  ///////////////////////////////

   groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  exportReportExcel() {
    if(this.admin1.length==0){
      return;
    }
   
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Reports');
    worksheet.addRow([]);
    worksheet.addRow(['','ACT:', this.clickAct]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow(['Act','Section','Major Head / Description','Severity','Maximum Punishment']);
    worksheet.getRow(5).font = { name: 'Calibri', bold: true };
    worksheet.getRow(5).alignment = { vertical: 'middle', horizontal: 'left' };
    ['A','B','C','D','E'].map((x)=> {
      worksheet.getCell(x+'5').border = {
        top: {style:'hair'},
        left: {style:'hair'},
        bottom: {style:'hair'},
        right: {style:'hair'}
      };
    });
    worksheet.columns = [
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 20 },
      { width: 30 },

    ];
    this.admin1.forEach((x,i) => {
      worksheet.addRow([
        x['act_code'],
        x['section_code'],
        (x['major_Head'] +' / '+ x['major_Head_Name']),
        x['severity'],
        x['max_punishment'],
        
        ,],"n");
      ['A','B','C','D','E'].map((x)=> {
        worksheet.getCell(x+(i+6).toString()).border = {
          top: {style:'hair'},
          left: {style:'hair'},
          bottom: {style:'hair'},
          right: {style:'hair'}
        };
      });
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Reports.xlsx');
    })
  }
  GetFormattedDate(date) {
    var todayTime = new Date(date);
    var month = todayTime .getMonth() + 1;
    if(month.toString()=='NaN'){
      return "dd-mm-yyyy"
    }
    var day = todayTime .getDate();
    var year = todayTime .getFullYear();
    return day + "-" + month + "-" + year;
}
  classifyFir1(){
   this.selectFirNo
   this.selectFirContent
   this.selectedState
   this.selectAct
   this.selectedSection
   this.selectedLanguages
   this.service.fetchClassifyr(this.selectFirContent,this.selectAct,this.selectedSection,this.selectedLanguages).subscribe((response) => {
 
    if(response.status){
      this.result=response.res.response;
    
       }
    else{
    }
  }, error => {
    this.fetchingData1=false;
    
  })
  }
 
 
  getFilteredFirData(AnalyticsTableData){
      if(this.selectedState !=='All'){
        AnalyticsTableData=AnalyticsTableData.filter(m=>m.state_code.toUpperCase()===this.selectedState.toUpperCase());
      }
      if(this.selectedStateDistrict !== 'All'){
        AnalyticsTableData=AnalyticsTableData.filter(m=>m.district_code.toUpperCase()===this.selectedStateDistrict.toUpperCase());
      }
      if(this.selectedIPC_SSl !== 'All'){
        AnalyticsTableData=AnalyticsTableData.filter(m=>m.ps_code.toUpperCase()===this.selectedIPC_SSl.toUpperCase());
      }
      // if(this.fromDate){
      //   AnalyticsTableData=AnalyticsTableData.filter(m=>m.reg_date>=this.fromDate);
      // }
      // if(this.toDate){
      //   AnalyticsTableData=AnalyticsTableData.filter(m=>m.reg_date<=this.toDate);
      // }
      // if(this.fromDate){
      //   this.date3=this.fromDate.getFullYear();
      //   this.date2=this.date3+1;
      //   this.date1=this.date3+2;
      // }
      // else{
      //   if(this.toDate){
      //     this.date1=this.toDate.getFullYear();
      //   this.date2=this.date1-1;
      //   this.date3=this.date1-2;
      //   }
      // }


      if(this.selectedCat !== 'All'){

        AnalyticsTableData=AnalyticsTableData.filter(m=>m.major_Head_Name.toUpperCase()===this.selectedCat.toUpperCase());
      }
      if(this.selectedSubcat !== 'All'){
    
        AnalyticsTableData=AnalyticsTableData.filter(m=>m.minor_Head_Name.toUpperCase()===this.selectedSubcat.toUpperCase());
      }
      if(AnalyticsTableData.length==0){
        this.messageInFirAnalytics='No data found'
      }
      return AnalyticsTableData;
  }

  majorSelected(event){
   
    if(this.selectedCat == 'All'){
      this.minorHead=this.allMinorHead;
      this.majorHeadFilter='All';
      this.selectedSubcat='All';
    }
    else{
      this.majorHeadFilter=this.majorHead.filter(m=>m.major_Head===event.value)[0].major_Head_Name;
   
      this.minorHead=this.allMinorHead.filter(m=>m.major_Head===event.value);
    }
  }
  majorMinorFilter(AnalyticsTableData){
    if(this.selectedCat !== 'All'){
     AnalyticsTableData=AnalyticsTableData.filter(m=>m.major_Head.toUpperCase()===this.selectedCat.toUpperCase());
   
    }
    if(this.selectedSubcat !== 'All'){
      
      AnalyticsTableData=AnalyticsTableData.filter(m=>m.minor_Head_Name.toUpperCase()===this.selectedSubcat.toUpperCase());
    }
    else{
      this.selectedSubcat='All';
    }
    if(AnalyticsTableData.length==0){
      this.messageInFirAnalytics='No data found'
    }
    return AnalyticsTableData;
  }

  classifyFir(){
  //   var obj={
  //     firNo:this.firNo;
  // }
    this.fileSubmitClickedFIRClassifier=true;

    this.fetchingData=false;
      this.fetchingData1=false;
      this.messageLine1='';
      this.messageLine3='';
      this.errorCase=false;
      this.errorCase1=false;
    
    if (!this.docs) {
      this.fileSelected = false
      return;
    }
    else {

      this.fileSelected = true;
      const data = new FormData();
      for (let file of this.docs) {
        data.append('file', file);
      };
      this.fetchingData=true;
     
      this.service.classifyFIRStoreFile(data).subscribe((response) => {
        this.fetchingData=false;
        
        if(response.status){
         this.fetchingData1=true;
         this.service.classifyFirModel(response.res.path).subscribe((response1) => {
          this.fetchingData1=false;
       
          if(response1.status){
            this.messageLine1='FIR Classification Model Started, '+response1.res.count+' FIRs Classified successfully.';
          }
          else{
            this.errorCase1=true;
          this.fetchingData1=false;
          this.messageLine3='Error occured while classifying FIRs'
          }
  
        }, error => {
          this.errorCase1=true;
          this.fetchingData1=false;
          this.messageLine3='Error occured while classifying FIRs'
          
        })
        }
        else{
          this.errorCase=true;
          this.fetchingData=false;
          this.messageLine1='Error occured while uploading file.'
        }

      }, error => {
        this.errorCase=true;
        this.fetchingData=false;
        this.messageLine1='Error occured while uploading file.'
      
      })
    }
  }
  /* -----------------------------------Train Model methods-------------------------*/
  // trainModel(){
  //   this.fetchingData1=true;
  //   this.service.fetchModelData().subscribe((response) => {
  //     this.fetchingData1=false;
  //   
  //     if(response.status){
  //       this.trainModelTableData=response.res;
  //     }
  //     else{
  //     }
  //   }, error => {
  //     this.fetchingData1=false;
  //     
  //   })
    trainModel(){
      
      this.fetchingData1=true;
      this.service.fetchModelData(this.changeModel1).subscribe((response) => {
        this.fetchingData1=false;
       
        if(response.status){
          this.trainModelTableData=response.res;
        }
        else{
        }
      }, error => {
        this.fetchingData1=false;
        
      })
  
    
  // this.trainModelTableData = [
  // { modelName: 'Mdl_20200314_01', trainingDate: '14-Mar-20', modelScore: '0.20', default: false },
  // { modelName: 'Mdl_20200314_02', trainingDate: '14-Mar-20', modelScore: '0.38', default: false },
  // { modelName: 'Mdl_20200418_01', trainingDate: '18-Apr-20', modelScore: '0.46', default: false },
  // { modelName: 'Mdl_20200418_02', trainingDate: '18-Apr-20', modelScore: '0.67', default: true },
  // { modelName: 'Mdl_20200514_01', trainingDate: '14-Ma7-20', modelScore: '0.54', default: false },
  // { modelName: 'Mdl_20200515_01', trainingDate: '15-May-20', modelScore: '0.45', default: false },
  
  // ]
  }

  
  setAccess_Allowed(data,data1,data2){
    this.errorMessage=''
   
    this.fetchingData=true;
    this.service.changeAccess(data,data1,data2).subscribe((response) => {
      this.fetchingData=false;
    
      if(response.status){
   
      this.changeAccessAction=response.status;
      this.getRoleFeature()
      this.messageInRole='Role Updated...'
    }
      else{
        
      }
    }, error => {
      this.fetchingData=false;
      this.errorMessage='Some error occured in changing setAccess_Allowed.'
     
    })
  }
  
  setModelAsDefault(data,data1){
    this.errorMessage=''
  
    this.fetchingData=true;
    this.service.changeDefaultModel(data,data1).subscribe((response) => {
      this.fetchingData=false;
      
      if(response.status){
        this.errorMessage="Default model changed successfully."
        this.trainModel()
      }
      else{
        this.errorMessage='Some error occured in changing default model.'
      }
    }, error => {
      this.fetchingData=false;
      this.errorMessage='Some error occured in changing default model.'
      
    })
  }
  changeLanguage(lang){
    if(lang=='eng'){
      this.lang='eng'
    }
    else if (lang=='hin'){
      this.lang='hin'
    }
  }
  changeClient(data){
    this.selectRoleData=data;
   
    this.getRoleFeature()
  }
  getLanguage(data){
    this.selectedLanguages=data;
  }
  changeModel(data){
    this.changeModel1=data;
    this.trainModel();
  }

  changeAct(data){
    this.clickAct=data;
  
  }
  onClick(){
    if(this.clickAct == 'All' || this.clickAct == 'all' ){
      this.report()
    }else{
  this.report1(this.clickAct)
    }
  }
  
  openUseModal(){
  //   const replaceModalRef = this.dialog.open(AdminComponent, {
      
  //    minHeight: '35vh',
  //   //  data: { type:"add_version",group:group },
  //   //  panelClass: 'add-version-dialog',
     
  //  });
  //  replaceModalRef.updatePosition({top:'28vh'})
  //  replaceModalRef.afterClosed().subscribe(response => {
     
  //  });

  const dialogRef = this.dialog.open(AdminComponent,{
    width:'500px',
    data: { userId:"0", type:"userModal" }
  });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
     

    });
  }

  updateUser(userId){
    const dialogRef = this.dialog.open(AdminComponent,{
      width:'500px',
      data: { userId:userId,type:"userModal" }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
      

    });
  }

  openDelete(userId){
    const dialogRef = this.dialog.open(AdminComponent,{
      width:'500px',
     // minHeight: '35vh',
      data: { userId:userId,type:"deleteModal" }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    

    });
  }

  addRole(){
    const dialogRef = this.dialog.open(AdminComponent,{
      width:'500px',
     // minHeight: '35vh',
      data: { userId:"0",type:"roleModal" }
    });

    dialogRef.afterClosed().subscribe(result => {
     // this.getAllUsers();
     this.getRole();
  

    });
  }

  changePassword(){
    const dialogRef = this.dialog.open(AdminComponent,{
      width:'500px',
      data: { userId:"0",type:"changePassModal" }
    });

    dialogRef.afterClosed().subscribe(result => {
     // this.getAllUsers();
    // this.getRole();
    

    });
  }
  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
			
    }

    modelDelete(modelID){
      this.service.deleteModelData(modelID).subscribe((response) => {
        
        if(response.status){
          this.trainModel()
        }
        else{
        }
      }, error => {
        this.fetchingData1=false;
        
      })
    }
    passReset(userId){
      const dialogRef = this.dialog.open(AdminComponent,{
        width:'500px',
        data: { userId:userId,type:"passResetModal" }
      });
  
      dialogRef.afterClosed().subscribe(result => {
       // this.getAllUsers();
      // this.getRole();
       
  
      });

    }

}
///Get state
