import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ncrbService} from './service/ncrb.service';
import { MatDialogModule} from '@angular/material/dialog';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule,} from "@angular/material/sidenav";
import { MatListModule} from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { LoginComponent } from './login/login.component';
import { HomeComponent} from './modules/home/home.component';
import { NcrbComponent } from './ncrb/ncrb.component';
import {MatSelectModule} from '@angular/material/select'
import { Globals } from './globals';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { MatInputModule} from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AdminComponent } from './modules/admin/admin.component';
const appRoutes: Routes = [
  
];
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
    appRoutes
    ),
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatInputModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NcrbComponent,
    AdminComponent
  ],
 
  exports:[MatButtonModule],
  providers: [ncrbService,Globals,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  entryComponents:[LoginComponent,AdminComponent]
})
export class AppModule { }
