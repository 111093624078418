<div class="toolbar1">
  <div class="col-xs-6 col-md-2 col-sm-6 logoDiv">
    <img src="../../assets/logo_white.png"  class="logoClass"  > 
    <!-- <div class="logoClass"></div> -->
  </div>
  <div class="col-xs-6 col-md-2 col-sm-6" style="width: 45%;padding:0%;color: black;margin-top: 9vh;">
    <!-- <img src="../../assets/logo1.png"  class="logoClass"  style="width: 70%;">  -->
    <div style="font-weight: 900;font-size: 17px;">राष्ट्रीय अपराध रिकॉर्ड ब्यूरो</div>
    <div style="font-weight: 700;font-size: 17px;">NATIONAL CRIME RECORDS BUREAU</div>
    <div style="color: #4a4343;">Empowering Indian Police with Information Technology</div>
  </div>
  <div style="border-bottom: 1px solid #e7e6e6;width:47%">
  
  <div class="col-md-5 col-md-offset- hidden-xs hidden-sm" style="text-align: end;height: 5vh;line-height: 5vh;font-size: 13px;width:31.6%;margin-left: 12%;">
    <a  class="contactUsTag" ><i class="material-icons" style="font-size: 20px;color:#aeabab">call</i><span class="mailPhone">1-800-400-8211</span>
    </a>
  </div>
  <div class="col-md-5 hidden-xs hidden-sm" style="text-align: center;height: 5vh;line-height: 5vh;font-size: 13px;width: 36.6%;padding: 0%;"><a  class="contactUsTag">
    <i class="material-icons" style="font-size: 20px;color:#aeabab">mail_outline</i>
    <span class="mailPhone">support@ncrb.gov.in</span>
  </a></div>
  <div class="col-md-2 col-md-offset-0 col-xs-3 col-xs-offset-3 col-sm-3 col-sm-offset-3" style="height: 5vh;line-height: 5vh;"><button mat-raised-button class="loginButton" (click)="login()">Login</button></div>
</div>
</div>
<div style="height: 95vh;margin-top: 5vh; background-color: #ddd;margin-bottom: 1px solid #e7e6e6;">
  <mat-toolbar >
    <mat-toolbar-row class="toolbar2" style="background-color: white;">
      <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm class="hideMenu">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="brandName">Retention Insight</div>
      <div fxShow="true" fxHide.lt-md class="hideTopNav" style="margin-left: 79%;">
        <a  [ngClass]="(activeLink=='home')?'active':'mailPhone'" mat-button>Home</a>
        <a  class="mailPhone" mat-button>Training</a>
        <a  class="mailPhone" mat-button>Help</a>
      
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav >
      <mat-nav-list>
        <a  mat-list-item [ngClass]="(activeLink=='home')?'sidebarActive':'sidebarNotActive'" style="color: #aeabab;">Home</a>
        <a  mat-list-item [ngClass]="(activeLink=='signup')?'sidebarActive':'sidebarNotActive'" style="color: #aeabab;">Training</a>
        <a  mat-list-item [ngClass]="(activeLink=='demo')?'sidebarActive':'sidebarNotActive'" style="color: #aeabab;">Help</a>
        <a  mat-list-item class="sidebarNotActive"><i class="material-icons">call</i><span class="mailPhone" style="color: #aeabab;">1-800-400-8211</span></a>
        <a  class="sidebarNotActive" mat-list-item><span class="material-icons">mail_outline</span><span class="mailPhone" style="color: #aeabab;">support@ncrb.gov.in</span></a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill *ngIf="activeLink=='home'" class="home">
   
  <div class="col-md-5 col-md-offset-1 col-xs-10 col-xs-offset-1 homeLine1">CCTNS</div>
  
  <div class="col-md-5 col-md-offset-1 col-xs-10 col-xs-offset-1 homeLine2">ML & NLP Based</div>
  
  <div class="col-md-5 col-md-offset-1 col-xs-10 col-xs-offset-1 homeLine2">FIR Analytics</div>
  
  <div class="col-md-5 col-md-offset-1 col-xs-10 col-xs-offset-1 homeLine2">System</div>
  

  
  <div class="copyrightline1" [ngStyle]="{'font-weight': 'bold'}"><span >Developed By</span></div>
  <div class="copyrightline2"><span >neurIOT Technologies</span></div>
  <div class="copyrightline3"><span >www.neuriot.com</span></div>
    </mat-sidenav-content>
    
    
  </mat-sidenav-container>
</div>
