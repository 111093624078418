

<div class="titleClass"  *ngIf="modal=='userModal'">
    <span >Add User</span>
    <span class="material-icons closeIcon" (click)="closeDialog()">highlight_off</span></div>
<form [formGroup]="addUserForm" style="margin: 3%;" *ngIf="modal=='userModal'">

    <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs">Email Id</div>
    <div class="col-lg-8 col-md-8 col-xs-12 signUpFormDivs1">
        <input id="previousVersion"  type="text" maxlength="40" placeholder="Enter Email Id"
        formControlName="emailId"
             class="form-control"  >
    </div>
    <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs">First Name</div>
    <div class="col-lg-8 col-md-8 col-xs-12 signUpFormDivs1">
        <input id="previousVersion"  type="text" maxlength="40" placeholder="Enter First Name"
        formControlName="firstName"
             class="form-control"  >
    </div>
    <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs">Last Name</div>
    <div class="col-lg-8 col-md-8 col-xs-12 signUpFormDivs1">
        <input id="previousVersion"  type="text" maxlength="40" placeholder="Enter Last Name"
        formControlName="lastName"
             class="form-control"  >
    </div>
    <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs" *ngIf="userId==0">Password</div>
    <div class="col-lg-8 col-md-8 col-xs-12 signUpFormDivs1" *ngIf="userId==0">
        <input id="previousVersion"  type="password" maxlength="40" placeholder="Enter Password"
        formControlName="password"
             class="form-control"  >
    </div>

   
    <!-- <mat-select class="firAnalyticsSelect4" style="margin-top: 0vh;" [(ngModel)]="selectedState"  placeholder="Select Role"  (selectionChange)="changeClient($event.value)">
        <mat-option  [value]="data.role_Id">
        data
        </mat-option>
      </mat-select> -->
      <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs">Role</div>
      <div class="col-lg-8 col-md-8 col-xs-12 signUpFormDivs1">
          <select id="previousVersion" maxlength="40"  formControlName="role"
               class="form-control"  >
               <option value="" hidden>select role</option>
            <option *ngFor="let rol of role" [value]="rol.role_Id">{{rol.role_Name}}</option>
            </select>
      </div>

    <div style="text-align: right;    padding: 0% 6%;">
        <button class="contactUsButton" (click)="submitUser()">Submit</button></div>
</form>

<!-- ----------------------------delete modal------------------------ -->

<div class="titleClass"  *ngIf="modal=='deleteModal'">
    <span >Delete User</span>
    <span class="material-icons closeIcon" (click)="closeDialog()">highlight_off</span></div>
    <div *ngIf="modal=='deleteModal'">
<p style="margin-top: 18px;">Do you want to delete the user?</p>
<div style="margin-top: 18px;">
    <button class="contactUsButton" (click)="deleteUser()">Yes</button>
    <button style="margin-left: 69px;" class="contactUsButton" (click)="closeDialog()">No</button></div>
    </div>

<!-- ----------------------------role modal------------------------ -->

<div class="titleClass"  *ngIf="modal=='roleModal'">
    <span >Add Role</span>
    <span class="material-icons closeIcon" (click)="closeDialog()">highlight_off</span></div>
<form [formGroup]="addRoleForm" style="margin: 3%;" *ngIf="modal=='roleModal'">

    <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs">Role</div>
    <div class="col-lg-8 col-md-8 col-xs-12 signUpFormDivs1">
        <input id="previousVersion"  type="text" maxlength="40" placeholder="Enter Role"
        formControlName="role"
             class="form-control"  >
    </div>
    <div class="col-sm-12" style="padding: 15px;font-size: 15px;font-weight: 600;">Screen Access</div>
    <!-- <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs"></div> -->
    <div class="col-lg-12 col-md-12 col-xs-12 signUpFormDivs1">
        <ul style="list-style-type: none;">
        <li formArrayName="features" *ngFor="let screen of featureData ; let i = index">
            <input type="checkbox" [formControlName]="i">
            {{featureData[i].feature_Name}}
        </li>
        </ul>
    </div>
    <div style="text-align: right;    padding: 0% 6%;">
        <button class="contactUsButton" style="    margin-top: 80px;" (click)="submitRole()">Submit</button></div>
</form>

<!-- -----------------------change password modal-------------------- -->

<div class="titleClass"  *ngIf="modal=='changePassModal'">
    <span >Password Reset</span>
    <span class="material-icons closeIcon" (click)="closeDialog()">highlight_off</span></div>
<form [formGroup]="changePassForm" style="margin: 3%;" *ngIf="modal=='changePassModal'">

    <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs">Old Password</div>
    <div class="col-lg-8 col-md-8 col-xs-12 signUpFormDivs1">
        <input id="previousVersion"  type="password" maxlength="40" placeholder="Enter Old Password"
        formControlName="oldPassword"
             class="form-control"  >
    </div>
    <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs">New Password</div>
    <div class="col-lg-8 col-md-8 col-xs-12 signUpFormDivs1">
        <input id="previousVersion"  type="password" maxlength="40" placeholder="Enter New Password"
        formControlName="password"
             class="form-control"  >
    </div>
    <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs">Confirm Password</div>
    <div class="col-lg-8 col-md-8 col-xs-12 signUpFormDivs1">
        <input id="previousVersion"  type="password" maxlength="40" placeholder="Re Enter Password"
        formControlName="cnfrmPass"
             class="form-control"  >
    </div>
  

   
    

    <div style="text-align: right;    padding: 0% 6%;">
        <button class="contactUsButton" (click)="submitPassword()">Submit</button></div>
</form>

<!-- -------------------------reset password of user----------------------- -->

<div class="titleClass"  *ngIf="modal=='passResetModal'">
    <span >Password Reset</span>
    <span class="material-icons closeIcon" (click)="closeDialog()">highlight_off</span></div>
<form [formGroup]="changePassForUserForm" style="margin: 3%;" *ngIf="modal=='passResetModal'">

    <div class="col-lg-4 col-md-4 col-xs-12 signUpFormDivs">Password</div>
    <div class="col-lg-8 col-md-8 col-xs-12 signUpFormDivs1">
        <input id="previousVersion"  type="password" maxlength="40" placeholder="Enter Password"
        formControlName="password"
             class="form-control"  >
    </div>
    <div style="text-align: right;    padding: 0% 6%;">
        <button class="contactUsButton" (click)="changesPassword()">Submit</button></div>
</form>