import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ncrbService } from '../service/ncrb.service';
import { Globals } from '../globals';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm:FormGroup;
  formSubmitted:boolean=false;
  errorMessage:string=''
  captchaSelected:boolean=true;
  captchaValidated:boolean=false;
  validatingCaptcha:boolean=false;
  submittingData:boolean=false;
  hide = true;
  forgetPasswordScreen:boolean=false;
  forgetPasswordForm:FormGroup;
  successMessage:string=''
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,public globals:Globals,private router:Router,private formBuilder: FormBuilder,private service:ncrbService,private dialogRef: MatDialogRef<LoginComponent>) { }

  ngOnInit(): void {
    this.loginForm=this.formBuilder.group({
      userName:['',Validators.required],
      password:['',Validators.required],
    })
    this.forgetPasswordForm=this.formBuilder.group({
      userName:['',Validators.required]
    })
    
  }
  
  resolved(captchaResponse: string) {
    this.errorMessage=''
    if(!captchaResponse){
      this.captchaSelected=false;
      this.errorMessage='Captcha expired';
      this.validatingCaptcha=false;
    }
    else{
      this.captchaSelected=true;
      this.validatingCaptcha=true;
    }
    
    this.captchaValidated=false
    this.service.captcha(captchaResponse).subscribe((response)=>{
      this.validatingCaptcha=false;
     
      if(response.status==true){
        this.captchaValidated=true;
      }
     
    },error=>{
      this.validatingCaptcha=false;
     
      
      
      
    })
   
  }
  
  get f() { return this.loginForm.controls; }

  loginFormChanged(){
    this.errorMessage=''
  }
  
  login(){
    this.formSubmitted=true;
    this.errorMessage='';
  //   if(!this.captchaSelected){
  //     this.errorMessage='Select Captcha to proceed.';
  //     return
  //   }
  //   if(!this.captchaValidated){
  //     this.errorMessage='Capcha is not validated.';
  //     return;
  //   }
  //   if (this.loginForm.invalid) {
  //     console.log("Login up form is invalid")
  //     return;
  // }
  this.submittingData=true;
  this.service.login(this.loginForm.value).subscribe((response)=>{
    this.submittingData=false;
    
    this.captchaSelected=false;
    this.captchaValidated=false;
    if(response.status){
      if(response.valid){
        
        sessionStorage.setItem('loggedinUser',JSON.stringify(response.res[0]));
        localStorage.setItem('token',response.token)
        this.dialogRef.close();
        this.router.navigate(['/ncrb']);
      }
      else{
      grecaptcha.reset();
      this.errorMessage=response.res;
      this.validatingCaptcha=false
      this.loginForm.reset();
      this.formSubmitted=false
      }
    }
  },error=>{
    this.submittingData=false;
    grecaptcha.reset();
    this.formSubmitted=false;
   
    if(error.error.res){
      this.errorMessage=error.error.res
    }
    else{
      this.errorMessage='Some error occured';
    }
    
  })
  }
  backToLogin(){
    this.forgetPasswordScreen=false;
    this.forgetPasswordForm.controls['userName'].setValue('');
    this.errorMessage='';
    this.successMessage='';
    grecaptcha.reset();
    this.captchaSelected=false;
    this.captchaValidated=false;
    this.validatingCaptcha=false;
  }
  closeDialog(){
    this.dialogRef.close();
  }

}


