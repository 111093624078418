import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-ncrb',
  templateUrl: './ncrb.component.html',
  styleUrls: ['./ncrb.component.css']
})
export class NcrbComponent implements OnInit {

  constructor(private dialog: MatDialog,) { }

  ngOnInit(): void {
  }
  activeLink:string="home";
  login(){
    const dialogRef2 = this.dialog.open(LoginComponent,{
      data:"Sign Up",
      panelClass: 'login-dialog',
   });
   dialogRef2.updatePosition({top:'12.5vh'})
  }


}
