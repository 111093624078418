<!DOCTYPE html>
<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <script type="text/javascript" src="canvasjs.min.js"></script>

</head>

<body *ngIf="loggedInUser">
  <div *ngIf="loggedInUser.userType=='A' || loggedInUser.userType=='M'" class="col-xs-12 hidden-lg hidden-md" style="height: 5vh;padding-top: 0.5vh;padding-left: 8px;padding-right: 1px;">
    <div class="col-xs-2" >
      <span class="material-icons" >
        menu
        </span>
    </div>
      <div class="col-xs-2 col-xs-offset-8" >
        <span class="material-icons" (click)="logout()">
          account_circle
          </span>
      </div>
  </div>

<!-- sidebar !--> 
  <div class="sidebar">
    <div class="sidebarTitle hideSidenav">
      <img src="../../../assets/logo_gray.png"  class="logo1">
      <div class="logo2">
        <div style="font-weight: 600;font-size: 10px;">राष्ट्रीय अपराध रिकॉर्ड ब्यूरो</div>
    <div style="font-weight: 600;font-size: 9px;">NATIONAL CRIME RECORDS BUREAU</div>
    <div style="color: #4a4343;font-size: 7px;">Empowering Indian Police with Information Technology</div>
      </div>
      </div>

      <div *ngFor="let screen of featureData">
    <div style="height:6vh;width:1%;background-color: #ff9933;float: left;" *ngIf="activeLink==screen.feature_Name"></div>
    <a class="hideSidenav" *ngIf="isAccess(screen.feature_Name)" (click)="changeSidenavOpt(screen.feature_Name)" [ngClass]="(activeLink==screen.feature_Name)?'active':'notactive'" style="border-bottom: 0.5px solid black;">
      <i [ngClass]="screen.icon" aria-hidden="true" [ngClass]="(activeLink==screen.feature_Name)?'sidebarListIconsSelected':'sidebarListIcons'"></i>
      <span class="sidebarListNames" *ngIf="lang=='eng'">{{screen.feature_Name}}</span>
      <span class="sidebarListNames" *ngIf="lang=='hin'">{{screen.feature_Name_Hindi}}</span>
    </a>
</div>
    <!-- <div style="height:6vh;width:1%;background-color: #ff9933;float: left;" *ngIf="activeLink=='firClassifier'"></div>
    <a class="hideSidenav" *ngIf="isAccessOfClassifier" (click)="changeSidenavOpt('firClassifier')" [ngClass]="(activeLink=='firClassifier')?'active':'notactive'" style="border-bottom: 0.5px solid black;">
      <i class="fa fa-list-alt" aria-hidden="true" [ngClass]="(activeLink=='firClassifier')?'sidebarListIconsSelected':'sidebarListIcons'"></i>
      <span class="sidebarListNames" *ngIf="lang=='eng'">FIR Classifier</span>
      <span class="sidebarListNames" *ngIf="lang=='hin'">प्रथम सूचना रिपोर्ट वर्गीकरणकर्ता</span>
    </a>
      
    <div style="height:6vh;width:1%;background-color: #ff9933;float: left;" *ngIf="activeLink=='modelTrainer'"></div>
    <a class="hideSidenav" *ngIf="isAccessOfTrainer" (click)="changeSidenavOpt('modelTrainer')" [ngClass]="(activeLink=='modelTrainer')?'active':'notactive'" style="border-bottom: 0.5px solid black;">
        <i class="fa fa-tachometer" aria-hidden="true" [ngClass]="(activeLink=='modelTrainer')?'sidebarListIconsSelected':'sidebarListIcons'"></i>
        <span class="sidebarListNames" *ngIf="lang=='eng'">Model Trainer</span>
        <span class="sidebarListNames" *ngIf="lang=='hin'">मॉडल ट्रेनर</span>
      </a>
        
    <div style="height:6vh;width:1%;background-color: #ff9933;float: left;" *ngIf="activeLink=='report'"></div>
    <a class="hideSidenav" *ngIf="isAccessReports" (click)="changeSidenavOpt('report')" [ngClass]="(activeLink=='report')?'active':'notactive'" style="border-bottom: 0.5px solid black;">
      <i class="fa fa-file-text" aria-hidden="true" [ngClass]="(activeLink=='report')?'sidebarListIconsSelected':'sidebarListIcons'"></i>
      <span class="sidebarListNames" *ngIf="lang=='eng'">Reports</span>
      <span class="sidebarListNames" *ngIf="lang=='hin'">रिपोर्ट</span>
    </a>
      
    <div style="height:6vh;width:1%;background-color: #ff9933;float: left;" *ngIf="activeLink=='admin'"></div>
    <a class="hideSidenav" *ngIf="isAccessAdmin" (click)="changeSidenavOpt('admin')" [ngClass]="(activeLink=='admin')?'active':'notactive'" style="border-bottom: 0.5px solid black;">
      <i class="fa fa-cogs" aria-hidden="true" [ngClass]="(activeLink=='admin')?'sidebarListIconsSelected':'sidebarListIcons'"></i>
      <span class="sidebarListNames" *ngIf="lang=='eng'">Admin</span>
    <span class="sidebarListNames" *ngIf="lang=='hin'">व्यवस्थापक</span>
    </a> -->
      
      <div class="hideSidenav copyright">
        <div style="height: 3vh;line-height: 3vh;font-weight: 700;">Developed by:</div>
        <div style="height: 3vh;line-height: 3vh;">neurIOT Technologies</div>
        <div style="height: 2vh;line-height: 2vh;font-size: 10px;">www.neurIOT.com</div></div>
  </div>
<!-- FIR Analytics !--> 
  <div class="content" *ngIf="activeLink=='FIR Analytics'">
    <div class="col-md-12 col-lg-12 hidden-xs hidden-sm toolbardiv">
      <div class="col-md-2 col-lg-2">
        <!-- <span style="cursor: pointer;" (click)="changeLanguage('eng')" [ngClass]="(lang=='eng')?'englishHindiActive':null">English</span>
        <span> | </span>
        <span (click)="changeLanguage('hin')" style="cursor: pointer;" [ngClass]="(lang=='hin')?'englishHindiActive':null">Hindi</span> -->
      </div>
      <div class="col-md-1 col-lg-1 col-md-offset-9 col-lg-offset-9 parentDIV" style="cursor: pointer;">
        <img src="../../../assets/policeManIcon.png"  style="height: 9vh;" >
        <div id="nav_menu">
          <a (click)="logout()">Log Out</a>
          <a (click)="changePassword()">Password Reset</a>
        </div>
    </div>
    </div>
    <div class="col-md-12 col-lg-12 col-xs-12 titlediv">Home > FIR Analytics</div>
    <!--Dropdowns-->
    
    <div class="col-lg-12 col-md-12 col-xs-12 firAnalyticsDiv1">
      <div class="col-md-1 col-lg-1 firAnalyticsDiv2" [ngStyle]="{'font-weight': 'bold'}">State/UT:</div>
      <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
      <mat-select class="firAnalyticsSelect" [(ngModel)]="selectedState"  placeholder="Select State">

        <mat-option value="All" >All</mat-option>

        <mat-option *ngFor="let data of stateUTSelect" [value]="data.state_code">
          {{data.state_name}}
        </mat-option>
      </mat-select>
    </div>
    
      <div class="col-md-1 col-lg-1 firAnalyticsDiv2" [ngStyle]="{'font-weight': 'bold'}">District:</div>
      <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
      <mat-select class="firAnalyticsSelect" [(value)]="selectedStateDistrict"  placeholder="Select District">

        <mat-option value="All">All</mat-option>
        <mat-option *ngFor="let data of districtSelect" [value]="data.value" placeholder="Select Disstrict">
          {{data.actual}}
        </mat-option>
      </mat-select>
    </div>



    <div class="col-md-1 col-lg-1 firAnalyticsDiv2" [ngStyle]="{'font-weight': 'bold'}">IPC/SSL:</div>
    <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
    <mat-select class="firAnalyticsSelect" [(value)]="selectedIPC_SSl"  placeholder="Select IPC/SSL">
    

      <mat-option *ngFor="let data of ipcsslSelect" [value]="data.value">
        {{data.actual}}
      </mat-option>
    </mat-select>
  </div>


      <div class="col-md-1 col-lg-1 firAnalyticsDiv2" [ngStyle]="{'font-weight': 'bold'}">From Date:</div>
      <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
        <input type="date" [(ngModel)]="fromDate" class="firAnalyticsDate">
    </div>
  


    <!-- <div class="col-md-1 col-lg-1 firAnalyticsDiv2" [ngStyle]="{'font-weight': 'bold'}">Grouping:</div>
    <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
    <mat-select class="firAnalyticsSelect" [(value)]="selectedGroup" [disabled]="true" placeholder="Select Group">
      <mat-option *ngFor="let data of groupSelect" [value]="data.value">
        {{data.actual}}
      </mat-option>
    </mat-select>
  </div> -->

    <div class="col-md-1 col-lg-1 firAnalyticsDiv2" [ngStyle]="{'font-weight': 'bold'}">To Date:</div>
    <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
      <input type="date" [(ngModel)]="toDate" class="firAnalyticsDate">
  </div>
  <div class="col-md-1 col-lg-1 firAnalyticsDiv2 visibility-hidden" [ngStyle]="{'font-weight': 'bold'}">To Date:</div>
  <div class="col-md-3 col-lg-3 firAnalyticsDiv3 visibility-hidden">
    <input type="date"  class="firAnalyticsDate">
</div>
  <!-- <div class="col-md-1 col-lg-1 firAnalyticsDiv2">Category:</div>
  <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
  <mat-select class="firAnalyticsSelect" [(value)]="selectedCat" [disabled]="true" placeholder="Select Category">
    <mat-option *ngFor="let data of categorySelect" [value]="data.value">
      {{data.actual}}
    </mat-option>
  </mat-select>
</div>
  <div class="col-md-1 col-lg-1 firAnalyticsDiv2">Sub-cat:</div>
  <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
  <mat-select class="firAnalyticsSelect" [(value)]="selectedSubcat" [disabled]="true" placeholder="Select Sub-cat">
    <mat-option *ngFor="let data of subcatSelect" [value]="data.value">
      {{data.actual}}
    </mat-option>
  </mat-select>
</div>
  <div class="col-md-1 col-lg-1 firAnalyticsDiv2">Sub-sub-cat:</div>
  <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
    <mat-select class="firAnalyticsSelect"[(value)]="selectedSubsubcat" [disabled]="true" placeholder="Select Sub-sub-cat">
      <mat-option *ngFor="let data of subsubcatSelect" [value]="data.value">
        {{data.actual}}
      </mat-option>
    </mat-select>
</div> -->
<!-- <div class="col-md-6 col-lg-1 firAnalyticsDiv2"></div> -->
<div class="col-md-1 col-lg-1 firAnalyticsDiv2" [ngStyle]="{'font-weight': 'bold'}" >Major Head:</div>
  <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
  <mat-select class="firAnalyticsSelect" (selectionChange)="majorSelected($event)" [(value)]="selectedCat"   placeholder="Select Major Head">

    <mat-option value="All">All</mat-option>
    <mat-option *ngFor="let data of majorHead" [value]="data.major_Head">
      {{data.major_Head_Name}}
    </mat-option>
  </mat-select>
</div>
  <div class="col-md-1 col-lg-1 firAnalyticsDiv2" [ngStyle]="{'font-weight': 'bold'}">Minor Head:</div>
  <div class="col-md-3 col-lg-3 firAnalyticsDiv3">
  <mat-select class="firAnalyticsSelect" [(value)]="selectedSubcat"  placeholder="Select Minor Head">
    <mat-option value="All">All</mat-option>
    <mat-option *ngFor="let data of minorHead" [value]="data.minor_Head_Name">
    {{data.minor_Head_Name}}
    </mat-option>
  </mat-select>
</div>

    </div>
    <!--Table-->
    <div class="col-lg-12 col-md-12 col-xs-12 firAnalyticsDiv5">
      <div class="col-lg-12 col-md-12 col-xs-12 firAnalyticsDiv4 commonGreenColor">
        <div class="col-md-1 col-lg-1" style="height: 8vh;line-height: 3vh;padding: 0%;padding-top: 1vh;text-align: center;border: 0.5px solid black;border-right:0px;word-break: break-all;border-bottom: 0px;"><div>Major/Minor</div><div>Code</div> </div>
        <div class="col-md-4 col-lg-4" style="height: 8vh;line-height: 8vh;padding: 0%;text-align: center;border: 0.5px solid black;border-right:0px;word-break: break-all;border-bottom: 0px;">Crime Head</div>
        <div class="col-md-7 col-lg-7" style="height: 8vh;line-height:8vh;padding: 0%;text-align: center;border: 0.5px solid black;border-bottom: 0px;">
        <div class="col-md-12 col-lg-12" style="line-height: 3.5vh;;padding: 0%;border-bottom: 0.5px solid black;">
        <div class="col-md-3 col-lg-3" style="padding: 0%;border-right: 0.5px solid black;line-height: 3.5vh;line-height: 3.5vh;word-break: break-all;width: 32.4%;">{{date3==0?"No  Data":date3}}</div>
        <div class="col-md-3 col-lg-3" style="padding: 0%;border-right: 0.5px solid black;line-height: 3.5vh;line-height: 3.5vh;word-break: break-all;width: 32.4%;" >{{date2==0?"No  Data":date2}}</div>
        <div class="col-md-6 col-lg-6" style="padding: 0%;line-height: 3.5vh;line-height: 3.5vh;word-break: break-all;width: 32.4%;">{{date1==0?"No  Data":date1}}</div>
        </div>
        <div class="col-md-12 col-lg-12" style="line-height: 4.5vh;padding: 0%;">
        <div class="col-md-1 col-lg-1" style="padding: 0%;border-right: 0.5px solid black;line-height: 4.5vh;line-height: 4.5vh;word-break: break-all;width: 16.2%;font-size: 13px;">Incidence</div>
        <div class="col-md-1 col-lg-1" style="padding: 0%;border-right: 0.5px solid black;line-height: 4.5vh;line-height: 4.5vh;word-break: break-all;width: 16.2%;font-size: 13px;">Crime Rate</div>
        <div class="col-md-1 col-lg-1" style="padding: 0%;border-right: 0.5px solid black;line-height: 4.5vh;line-height: 4.5vh;word-break: break-all;width: 16.2%;font-size: 13px;">Incidence</div>
        <div class="col-md-1 col-lg-1" style="padding: 0%;border-right: 0.5px solid black;line-height: 4.5vh;line-height: 4.5vh;word-break: break-all;width: 16.2%;font-size: 13px;">Crime Rate</div>
        <div class="col-md-1 col-lg-1" style="padding: 0%;border-right: 0.5px solid black;line-height: 4.5vh;line-height: 4.5vh;word-break: break-all;width: 16.2%;font-size: 13px;">Incidence</div>
        <div class="col-md-1 col-lg-1" style="padding: 0%;line-height: 4.5vh;line-height: 4.5vh;word-break: break-all;width: 16.2%;font-size: 13px;">Crime Rate</div>
        <!-- <div class="col-md-1 col-lg-1" style="padding: 0%;line-height: 3.5vh;line-height: 2.5vh;width:14.8%;font-size: 10px;">% share to IPC Crimes</div> -->
        </div>
        
        </div>
        <div class="col-md-1 col-lg-1" style="height: 3.5vh;line-height: 3.5vh;padding: 0%;text-align: center;border: 0.5px solid black;border-right:0px;word-break: break-all;">1</div>
        <div class="col-md-4 col-lg-4" style="height: 3.5vh;line-height: 3.5vh;padding: 0%;text-align: center;border: 0.5px solid black;border-right:0px;word-break: break-all;">2</div>
        <div class="col-md-7 col-lg-7" style="height: 3.5vh;line-height: 3.5vh;padding: 0%;text-align: center;border: 0.5px solid black;">

        <div class="col-md-12 col-lg-12" style="line-height: 3.5vh;padding: 0%;">
        <div class="col-md-1 col-lg-1" style="padding: 0%;border-right: 0.5px solid black;height: 3.5vh;line-height: 3.5vh;word-break: break-all;width: 16.2%;">3</div>
        <div class="col-md-1 col-lg-1" style="padding: 0%;border-right: 0.5px solid black;height: 3.5vh;line-height: 3.5vh;word-break: break-all;width: 16.2%;">4</div>
        <div class="col-md-1 col-lg-1" style="padding: 0%;border-right: 0.5px solid black;height: 3.5vh;line-height: 3.5vh;word-break: break-all;width: 16.2%;">5</div>
        <div class="col-md-1 col-lg-1" style="padding: 0%;border-right: 0.5px solid black;height: 3.5vh;line-height: 3.5vh;word-break: break-all;width: 16.2%;">6</div>
        <div class="col-md-1 col-lg-1" style="padding: 0%;border-right: 0.5px solid black;height: 3.5vh;line-height: 3.5vh;word-break: break-all;width: 16.2%;">7</div>
        <div class="col-md-1 col-lg-1" style="padding: 0%;line-height: 3.5vh;word-break: break-all;width: 16.2%;">8</div>
        <!-- <div class="col-md-1 col-lg-1" style="padding: 0%;height: 3.5vh;line-height: 3.5vh;width:14.8%">9</div> -->
        </div>
        
        </div>
      </div>
      <!-- <div class="col-md-12 col-lg-12" style="padding: 0%;height: 40vh;overflow-y: overlay;" *ngIf="firAnalyticsTableData.length!=0">
      <div class="col-md-12 col-lg-12 firAnalyticsDiv4" *ngFor="let data of firAnalyticsTableData" style="margin-top: 0%;background-color: white;">
        <div class="col-md-1 col-lg-1 firAnalyticstd1">{{data.sNo}}</div>
        <div class="col-md-4 col-lg-4" style="height: 3.5vh;line-height: 3.5vh;padding: 0% 1vh;border: 0.5px solid black;border-top:0px;word-break: break-all;border-right: 0px;">{{data.headName}}</div>
        <div class="col-md-7 col-lg-7 firAnalyticstd2">
          <div class="col-md-12 col-lg-12" style="line-height: 3.5vh;padding: 0%;border-left: 0.5px solid black;">
            <div class="col-md-1 col-lg-1 firAnalyticstd3" >{{data.yearData2018}}</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width:14.15%">{{data.yearDataPerCent2018=='0' ? '0' :data.yearDataPerCent2018/overAllCrimeSum1  | number}}</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" >{{data.yearData2019}}</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width:14.15%">{{data.yearDataPerCent2019=='0' ? '0' :data.yearDataPerCent2019/overAllCrimeSum2  | number}}</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="text-align: end;" >{{data.yearData2020}}</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width:14.15%">{{data.yearDataPerCent2020=='0' ? '0' :data.yearDataPerCent2020/overAllCrimeSum3  | number}}</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width: 14.8%;text-align: end;">{{data.percentage}}</div>
            </div>
        </div>
      </div>
      
    </div> -->
    <div class="col-md-12 col-lg-12" style="padding: 0%;height: 40vh;overflow-y: overlay;" *ngIf="customResult.length!=0">
    <div *ngFor="let data of customResult;">
      <div class="col-md-12 col-lg-12 firAnalyticsDiv4"  style="margin-top: 0%;background-color: white;"
      [ngStyle]="{'font-weight': data.flag=='major' ? 'bold' : '' }"
>
        <div class="col-md-1 col-lg-1 firAnalyticstd1">{{data.code}}</div>
        <div class="col-md-4 col-lg-4" style="height: 3.5vh;line-height: 3.5vh;padding: 0% 1vh;border: 0.5px solid black;border-top:0px;word-break: break-all;border-right: 0px;">{{data.flag=='minor' ?'-' :''}}{{data.title}}</div>
        <div class="col-md-7 col-lg-7 firAnalyticstd2">
          <div class="col-md-12 col-lg-12" style="line-height: 3.5vh;padding: 0%;border-left: 0.5px solid black;">
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width:16.20%">{{ data[date3.toString()] || 0 }}</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width:16.20%">{{ data[date3.toString()+"_rate"] || 0 }}%</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width:16.20%" >{{ data[date2.toString()] || 0 }}</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width:16.20%">{{ data[date2.toString()+"_rate"] || 0  }}%</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width:16.20%; text-align: end;" >{{ data[date1.toString()] || 0 }}</div>
            <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width:18.20%">{{ data[date1.toString()+"_rate"] || 0 }}%</div>
            <!-- <div class="col-md-1 col-lg-1 firAnalyticstd3" style="width: 14.8%;text-align: end;">{{0}}</div> -->
            </div>
        </div>
      
    </div>
  </div>
      
    </div>
    <div class="col-md-12 col-lg-12" style="margin-left: 1%;padding: 1%;height: 40vh;width: 98%;border: 0.5px solid black;border-top: none;" *ngIf="customResult.length==0">
    {{messageInFirAnalytics}}
    </div>
    <div class="firAnalyticsLowerdiv">
         <button class="firAnalyticsAnalyzeButton commonButtonColor" (click)="firAnalyticsAnalyze()">Analyze</button>
         <button class="firAnalyticsDownloadButton commonButtonColor" (click)="exportExcel()">Download</button></div>
    </div>
   

  </div>
  <!-- FIR classifier !--> 
  <div class="content" *ngIf="activeLink=='FIR Classifier'">
    <div class="col-md-12 col-lg-12 hidden-xs hidden-sm toolbardiv">
      <div class="col-md-2 col-lg-2">
        <!-- <span style="cursor: pointer;" (click)="changeLanguage('eng')" [ngClass]="(lang=='eng')?'englishHindiActive':null">English</span>
        <span> | </span>
        <span (click)="changeLanguage('hin')" style="cursor: pointer;" [ngClass]="(lang=='hin')?'englishHindiActive':null">Hindi</span> -->
      </div>
      <div class="col-md-1 col-lg-1 col-md-offset-9 col-lg-offset-9 parentDIV" style="cursor: pointer;">
        <img src="../../../assets/policeManIcon.png" style="height: 9vh;" >
        <div id="nav_menu">
          <a (click)="logout()">Log Out</a>
          <a (click)="changePassword()">Password Reset</a>
        </div>
    </div>
    </div>
    
    <div class="col-md-12 col-lg-12 col-xs-12 titlediv">Home > FIR Classifier</div>
    <div class="col-md-12 col-lg-12" style="width: 96%;margin-left: 2%;height: 5vh;line-height: 5vh;padding: 0%;font-weight: 600;">
    Either upload an excel file with FIR details and press Classify or click on CCTNS button to get CCTNS database:
    </div>
    <div class="col-md-12 col-lg-12 col-xs-12 searchinputdiv changeInXs">
      <div class="col-md-1 col-lg-1 hidden-xs hidden-sm" style="font: size 15px;font-weight: bold; padding: 0%;height: 5vh;line-height: 5vh;margin-top: 1vh;">FIR Data :</div>
      <div class="col-md-3 col-lg-2 col-xs-12 xsRemovePadding" style="padding: 0%;width: 34.3%;">
        <div style="width: 90%;border: 0.5px solid gray;height: 5vh;padding: 0% 2%;line-height: 5vh;margin-top: 1vh;background-color: white;">
          <span *ngIf="!docs" [ngStyle]="{'color':fileSubmitClickedFIRClassifier && !fileSelected ? 'red' : '' }">No File Selected</span>
          <span *ngIf="docs">{{docs[0].name}}</span>
          </div>
      </div>
      
        <!-- <div class="col-md-1 hidden-xs hidden-sm" style="font: size 15px;padding: 0%;height: 5vh;line-height: 3vh;margin-top: 2vh; font-weight: bold; padding-left: -20px;" >Language :</div>
        <div class="col-md-2 hidden-xs hidden-sm" style="font-size:small;padding: 0%;height: 5vh;line-height: 3vh;margin-top: 1vh;"> 
          <mat-select class="firAnalyticsSelect22 "   placeholder="Select Language" (selectionChange)="getLanguage($event.value)">
            <mat-option *ngFor="let data of allLanguages" [value]="data.language_Code">
              {{data.language_Name}}
            </mat-option>
          </mat-select>
        

        </div> -->
      
      <div class="col-md-2 col-lg-3 col-xs-6 xsRemovePadding" style="padding: 0%;border-right: 0px solid black;height: 5vh;margin-top: 1vh;">
        <input id='browse' type='file' name="docs"  (change)="onSelectFile($event)"/>
        <button class="commonButtonColor selectFileBtn" id='browser' onclick='browse.click()' (change)="onSelectFile($event)">Browse</button>
        <button class="commonButtonColor" style="width: 44%;margin-left: 4%;height:5vh;border: none;" (click)="classifyFir()">Classify</button>
      </div>
      
        <!-- <div class="col-md-4 col-lg-4" style="height: 5vh;line-height: 5vh;padding: 0%;">Column name for FIR Text</div>
        <div class="col-md-4 col-lg-4"  style="height: 5vh;line-height: 5vh;padding: 0%;">Language</div>
        <div class="col-md-4 col-lg-4"  style="height: 5vh;line-height: 5vh;padding: 0%;">Mix <i class="fa fa-info-circle" aria-hidden="true"></i></div>
        <div class="col-md-4 col-lg-4" style="padding: 0%;"><input type="text" [(ngModel)]="columnName" style="height: 5vh;width: 95%;" [ngStyle]="{'border':fileSubmitClickedFIRClassifier && columnName=='' ? '1px solid red' : '' }"/></div>
        <div class="col-md-4 col-lg-4" style="padding: 0%;">
          <mat-select class="firAnalyticsSelect" [(value)]="language" placeholder="Select Language of FIRs in uploaded file." style="height: 5vh;margin-top: 0%;padding: 1% 2%;width: 95%;">
            <mat-option *ngFor="let data of langDropdown" [value]="data.value">
              {{data.actual}}
            </mat-option>
          </mat-select>
        </div>
        <div class="col-md-4 col-lg-4" style="padding: 0%;">
          <mat-select class="firAnalyticsSelect" [(value)]="mix" placeholder="Combination of English and Hindi" style="height: 5vh;margin-top: 0%;padding: 1% 2%;width: 95%;">
            <mat-option *ngFor="let data of mixDropdown" [value]="data.value">
              {{data.actual}}
            </mat-option>
          </mat-select>
        </div> -->
        <div class="col-md-12 col-lg-12 firClassifierLowerDiv">
      <!-- <div class="col-md-12 col-lg-12 col-xs-12 messagesLeftAlign">Status - </div> -->
      <div *ngIf="fetchingData" class="col-md-12 col-lg-12 col-xs-12 messagesLeftAlign">
        <i class="fa fa-spinner fa-spin loaderIcon" ></i><span class="messagesText">Uploading file...</span>
      </div>
      <div *ngIf="messageLine1" class="col-md-12 col-lg-12 col-xs-12 messagesLeftAlign" [ngClass]="(errorCase)?'errorMsg':null"><span [ngStyle]="{'font-weight': 'bold'}"> Status - </span>{{messageLine1}}</div>
      <div *ngIf="fetchingData1" class="col-md-12 col-lg-12 col-xs-12 messagesLeftAlign">
        <i class="fa fa-spinner fa-spin loaderIcon" ></i><span class="messagesText">Classifying FIRs...</span>
      </div>
      <div *ngIf="messageLine3" class="col-md-12 col-lg-12 col-xs-12 messagesLeftAlign" [ngClass]="(errorCase1)?'errorMsg':null" ><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{messageLine3}}</span></div>

    </div>
    </div>
    <div class="col-md-12 col-lg-12 col-xs-12 searchinputdiv2 changeInXs">
      
      <div class="col-md-1 hidden-xs hidden-sm" style="font: size 15px;font-weight: bold;padding: 0%;height: 5vh;line-height: 5vh;margin-top: 1vh; font-weight: bold" >FIR No :</div>
      <div class="col-md-5 hidden-xs hidden-sm" style="font-size:small;padding: 0%;height: 5vh;line-height: 5vh;margin-top: 1vh;">
        <input type="text" value="24482027190307" size="62" [(ngModel)]="selectFirNo" >

      </div>
      <div class="col-md-3  removePadding" style="padding: 0%;">
        <button class="commonButtonColor" style="width: 92%;margin-left: -12%;height:5vh;margin-top: 1vh;border: none;">Select FIR from CCTNS</button>
      </div>
      <div class="col-md-3  removePadding" style="padding: 0%;">
        <button class="commonButtonColor" style="width: 44%;margin-left: 4%;height:5vh;border: none; margin-top: 7px;" (click)="classifyFir1()">Classify</button>

      </div>
      <div class="col-md-12 changeInXs">
        <div class="col-md-8">
      <div class="col-md-2 hidden-xs hidden-sm" style="font: size 15px;padding: 0%;height: 5vh;line-height: 5vh;margin-top: 2vh; margin-left: -4%; font-weight: bold">FIR content :</div>
      <div class="col-md-10 hidden-xs hidden-sm" style="font-size:small;padding: 0%;height: 5vh;line-height: 5vh;margin-top: 2vh;margin-left: -3%">
        <!-- <textarea id="w3review"  type="text" name="w3review" rows="3" cols="64" [(ngModel)]="selectFirContent"></textarea> -->
         <!-- <input class="textF" type="text" value="" size="80"  [(ngModel)]="selectFirContent"> -->
         <textarea style=" line-height: 1; font-size: 12px; " id="w3review"  type="text" name="w3review" rows="8" cols="64" [(ngModel)]="selectFirContent"></textarea>
      </div>
        </div>
     <div class="col-md-4">  
      <div class="col-md-12">
      <div class="col-md-4 hidden-xs hidden-sm" style="font: size 15px;padding: 0%;height: 5vh;line-height: 3vh;margin-top: 2vh; font-weight: bold">Act : </div>
      <div class="col-md-8 hidden-xs hidden-sm" style="font-size:small;padding: 0%;height: 5vh;line-height: 3vh;margin-top: 2vh;">
        <input type="text" value="" [(ngModel)]="selectAct" >
      </div>
      </div>
      <div class="col-md-12">
        <div class="col-md-4 hidden-xs hidden-sm" style="font: size 15px;padding: 0%;height: 5vh;line-height: 3vh;margin-top: 1vh; font-weight: bold">Section :</div>
        <div class="col-md-8 hidden-xs hidden-sm" style="font-size:small;padding: 0%;height: 5vh;line-height: 3vh;margin-top: 1vh;">
          <input type="text" value=""  [(ngModel)]="selectedSection">
        </div>
        </div>
      <div class="col-md-12">
        <div class="col-md-4 hidden-xs hidden-sm" style="font: size 15px;padding: 0%;height: 5vh;line-height: 3vh;margin-top: 1vh; font-weight: bold;">Language :</div>
        <div class="col-md-8 hidden-xs hidden-sm" style="font-size:small;padding: 0%;height: 5vh;line-height: 3vh;margin-top: 1vh;"> 
          <mat-select class="firAnalyticsSelect2 "   placeholder="Select Language" (selectionChange)="getLanguage($event.value)">
            <mat-option *ngFor="let data of allLanguages" [value]="data.language_Code">
              {{data.language_Name}}
            </mat-option>
          </mat-select>
        

        </div>
      </div>

      </div>
      </div>
      </div>
      <div class="col-md-12 col-lg-12 firClassifierMiddleDiv">
      <div class="col-md-4" style="font-weight: bold; font-size: 15px; margin-top: 1vh;">
        FIR Predicted Details
        For Single FIR :

        </div>
        <div class="col-md-6">
          <table id="c" style="width: 100%;">
            <tr>
             
              <th>Major Head-1 / Description</th>
              <th>Major Head-2 / Description</th>
              <th>Major Head-3 / Description</th>
              
          </table>
          
          <table id="customers" style="width: 100%;">
            
            <tr *ngFor="let data of result">
              <td >{{data.Major_head1}} / {{data.Major_head1_description}}</td>
              <td >{{data.Major_head2}} / {{data.Major_head2_description}}</td>
              <td >{{data.Major_head3}} / {{data.Major_head3_description}}</td>
              
            </tr>

          </table>
          <table id="c"style="width: 100%; ">
            <tr>
              <th>Minor Head-1 / Description</th>
              <th>Minor Head-2 / Description</th>
              <th>Minor Head-3 / Description</th>
              
          </table>
          <table id="customers" style="width: 100%;">
            
            <tr *ngFor="let data of result">
              <td >{{data.Minor_head1}} / {{data.Minor_head1_description}}</td>
              <td >{{data.Minor_head2}} / {{data.Minor_head2_description}}</td>
              <td >{{data.Minor_head3}} / {{data.Minor_head3_description}}</td>
              
            </tr>

          </table>
          
        </div>
        
      </div>
    
  </div>
  <!-- Model Trainer !--> 
  <div class="content" *ngIf="activeLink=='Model Trainer'">
    <div class="col-md-12 col-lg-12 hidden-xs hidden-sm toolbardiv">
      <div class="col-md-2 col-lg-2">
        <!-- <span style="cursor: pointer;" (click)="changeLanguage('eng')" [ngClass]="(lang=='eng')?'englishHindiActive':null">English</span>
        <span> | </span>
        <span (click)="changeLanguage('hin')" style="cursor: pointer;" [ngClass]="(lang=='hin')?'englishHindiActive':null">Hindi</span> -->
      </div>
      <div class="col-md-1 col-lg-1 col-md-offset-9 col-lg-offset-9 parentDIV" style="cursor: pointer;">
        <img src="../../../assets/policeManIcon.png" style="height: 9vh;" >
        <div id="nav_menu">
          <a (click)="logout()">Log Out</a>
          <a (click)="changePassword()">Password Reset</a>
        </div>
    </div>
    </div>
    
    <div class="col-md-12 col-lg-12 col-xs-12 titlediv">Home > Model Trainer</div>
    <div class="col-md-12 col-lg-12 modelTrainerOuterdiv">
      <!-- <div class="col-md-11 col-lg-11 col-xs-11 modelTrainerDiv1 changeInXs">
        <div class="col-md-1 col-lg-1 hidden-xs hidden-sm" style="font-size:small;padding: 0%;height: 5vh;line-height: 5vh;margin-top: 1vh;width:15%">Training Data :</div>
      <div class="col-md-4 col-lg-4 col-xs-12 xsRemovePadding" style="padding: 0%;width: 35%;">
        <div style="width: 100%;border: 0.5px solid #d8d8d8;height: 5vh;padding: 0% 2%;line-height: 5vh;margin-top: 1vh;background-color: white;">
          <span *ngIf="!docs">No File Selected</span>
          <span *ngIf="docs">{{docs[0].name}}</span>
          </div>
      </div>
      <div class="col-md-4 col-lg-4 col-xs-6 xsRemovePadding" style="padding: 0%;height: 5vh;margin-top: 1vh;width: 50%;">
        <input id='browse' type='file' name="docs"  (change)="onSelectFile($event)"/>
        <button class="commonButtonColor selectFileBtn" id='browser' onclick='browse.click()' (change)="onSelectFile($event)" style="width: 30%;margin-left: 34%;margin-right: 0%;">Browse</button>
        <button class="commonButtonColor" style="width: 30%;margin-left: 3%;margin-right: 3%;height:5vh;border: none;">Train</button>
      </div>
      <div class="col-md-1 col-lg-1 hidden-xs hidden-sm" style="font-size:small;padding: 0%;height: 5vh;line-height: 5vh;margin-top: 1vh;width:15%">Language :</div>
      <div class="col-md-4 col-lg-4" style="padding: 0%;width: 35%;">
        <mat-select class="modelTrainerSelect" >
          <mat-option *ngFor="let data of stateUTSelect" [value]="data.value">
            {{data.actual}}
          </mat-option>
        </mat-select>
      </div>
      </div> -->
      
      <div class="col-md-12 col-lg-12 col-xs-12 searchinputdiv1 changeInXs">
        <div class="center">

        <div class="col-md-6 col-lg-1 firAnalyticsDiv2 change1" [ngStyle]="{'font-weight': 'bold' }">Language:</div>
      <div class="col-md-6 col-lg-3 firAnalyticsDiv3">
        
      
    
        <mat-select class="firAnalyticsSelect4"   placeholder="Select Language"  (selectionChange)="changeModel($event.value)">
          <mat-option *ngFor="let data of allLanguages" [value]="data.language_Code">
            {{data.language_Name}}
          </mat-option>

      </mat-select>
    </div>
    </div>
      </div>
      <div class="col-md-12-col-lg-12 col-xs-12 modelTrainerDiv2" [ngStyle]="{'font-weight': 'bold' }">Set Default model to be used for FIR Classification:</div>     
      <div class="col-md-12-col-lg-12 col-xs-12 trainModelDiv">
        <table class="col-md-12 col-lg-12 col-xs-12 dbActualTable changeSize" style="border-collapse: collapse;">
          <tr class="dbTableHeader commonGreenColor">
            <td class="col-md-3 col-lg-3 col-xs-3 headingBorderRight">Training Model Name</td>
            <td class="col-md-3 col-lg-3 col-xs-3 headingBorderRight">Training Date</td>
            <td class="col-md-3 col-lg-3 col-xs-3 headingBorderRight">Model F1 Score</td>
            <td class="col-md-3 col-lg-3 col-xs-3">Actions</td></tr>
          <tr *ngFor="let data of trainModelTableData" style="height:6vh">
            <th style="text-align: center;">{{data.model_Name}}</th>
            <th style="text-align: center;">{{data.training_date | date:'dd-MMM-yyyy'}}</th>
            <th style="text-align: center;">{{data.f1_Score}}</th>
            <th>
              <span style="margin-right: 1vh;text-decoration: underline;text-align: center;" (click)="modelDelete(data.model_id)"><a>Delete</a></span> 
              <span *ngIf="data.default_code==1"style="text-decoration: underline;"><a>Default in use</a></span>
              <span *ngIf="data.default_code==0" style="text-decoration: underline;" (click)="setModelAsDefault(data.model_id,data.language_Code)"><a>Set as Default</a></span></th></tr>

        </table>
        
      </div>
      <div *ngIf="errorMessage" class="col-md-12 col-lg-12 col-xs-12 messagesText">{{errorMessage}}</div>
      <div *ngIf="fetchingData" class="col-md-12 col-lg-12 col-xs-12">
        <i class="fa fa-spinner fa-spin loaderIcon" ></i><span class="messagesText">Updating default model...</span>
      </div>
      <div *ngIf="fetchingData1" class="col-md-12 col-lg-12 col-xs-12">
        <i class="fa fa-spinner fa-spin loaderIcon" ></i><span class="messagesText">Fetching Models...</span>
      </div>
      <!-- <div class="col-md-12 col-lg-12 modelTrainerLowerDiv">
        <button class="modelTrainerdownloadButton commonButtonColor">Download Template</button>
      </div> -->
    </div>

  

   </div>
   <!-- Reports !--> 
   <div class="content" *ngIf="activeLink=='Reports'">
    <div class="col-md-12 col-lg-12 hidden-xs hidden-sm toolbardiv">
      <div class="col-md-2 col-lg-2">
        <!-- <span style="cursor: pointer;" (click)="changeLanguage('eng')" [ngClass]="(lang=='eng')?'englishHindiActive':null">English</span>
        <span> | </span>
        <span (click)="changeLanguage('hin')" style="cursor: pointer;" [ngClass]="(lang=='hin')?'englishHindiActive':null">Hindi</span> -->
      </div>
      <div class="col-md-1 col-lg-1 col-md-offset-9 col-lg-offset-9 parentDIV" style="cursor: pointer;">
        <img src="../../../assets/policeManIcon.png" style="height: 9vh;" >
        <div id="nav_menu">
          <a (click)="logout()">Log Out</a>
          <a (click)="changePassword()">Password Reset</a>
        </div>
    </div>
    </div>
    <div class="col-md-12 col-lg-12 col-xs-12 titlediv">Home > Reports > {{selectedReportOpt}}</div>
  
  <div class="col-md-12 col-lg-12 col-xs-12 setupBtnDiv">
    <!-- <div class="col-md-2 col-lg-2 col-xs-3 setupBtnRemovePadding">
      <button class="setupBtns"   [ngClass]="(selectedReportOpt=='Training Logs')?'activeBtnSetup':null" style="cursor: pointer;">Training Logs</button>
    </div>
    <div class="col-md-2 col-lg-2 col-xs-3 setupBtnRemovePadding">
      <button class="setupBtns"   [ngClass]="(selectedReportOpt=='Classification Logs')?'activeBtnSetup':null" style="cursor: pointer;">Classification Logs</button>
    </div>
    <div class="col-md-2 col-lg-2 col-xs-3 setupBtnRemovePadding">
      <button class="setupBtns"   [ngClass]="(selectedReportOpt=='FIR Analytics')?'activeBtnSetup':null" style="cursor: pointer;">FIR Analytics</button>
    </div> -->
  <div class="setupDiv">
    <div class="col-md-12 col-lg-12 col-xs-12  changeInXs" style="margin-bottom: 1%;height: 9vh;border: 0.5px solid #31538f;width: 100%;margin-left: 0%;margin-top: 1%;">
      <div class="center">
  
      <div class="col-md-6 col-lg-1 firAnalyticsDiv2 change1" [ngStyle]="{'font-weight': 'bold','font-size':'20px;'}">Act:</div>
    <div class="col-md-6 col-lg-3 firAnalyticsDiv31">
      <mat-select class="firAnalyticsSelect4" style="margin-top: 0vh;line-height: 5vh;"   placeholder="All/Selective"  (selectionChange)="changeAct($event.value)">

        <mat-option value="All">All</mat-option>
        <mat-option *ngFor="let data of act1" [value]="data.act_code">
          {{data.act_code}}

        </mat-option>
      </mat-select>
  </div>
  <div class="col-md-6 col-lg-1 firAnalyticsDiv2 change1" [ngStyle]="{'font-weight': 'bold' }">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button style="background-color: #4474C4; color: white; width: 70px; border: none;" (click)="onClick()">View</button></div>
</div></div>
<table id="excel-table">  
    <table id="tableAct">
      <tr>
        <th>Act</th>
        <th>Section </th>
        <th>Major Head / Description</th>
        <th>Severity</th>
        <th>Maximum Punishment</th>        
      </tr>
</table>
    <div style="overflow-x:hidden; height: 300px;   overflow: auto; margin-top: 0%;">
     
      <table id="table" >
        <tr *ngFor="let data of admin1;">
          <td >{{data.act_code}}</td>
          <td>{{data.section_code.replace("-", " - ")}}</td>

          <td>{{data.major_Head}} / {{data.major_Head_Name}}</td>
          <td>{{data.severity}}</td>
          <td style="word-break:break-all">{{data.max_punishment}}</td>    
        </tr>
      </table>
     
      </div>
      </table>
    <div class="lowerDiv1">
      <button  class="addGroupBtn commonButtonColor" (click)="exportReportExcel()">Download</button>
    </div>
  </div>
  </div>
   </div>
<!-- admin !--> 
<div class="content" *ngIf="activeLink=='Admin'">
  <div class="col-md-12 col-lg-12 hidden-xs hidden-sm toolbardiv">
    <div class="col-md-2 col-lg-2">
      <!-- <span style="cursor: pointer;" (click)="changeLanguage('eng')" [ngClass]="(lang=='eng')?'englishHindiActive':null">English</span>
      <span> | </span>
      <span (click)="changeLanguage('hin')" style="cursor: pointer;" [ngClass]="(lang=='hin')?'englishHindiActive':null">Hindi</span> -->
    </div>
    <div class="col-md-1 col-lg-1 col-md-offset-9 col-lg-offset-9 parentDIV" style="cursor: pointer;">
      <img src="../../../assets/policeManIcon.png" style="height: 9vh;" >
      <div id="nav_menu">
        <a (click)="logout()">Log Out</a>
        <a (click)="changePassword()">Password Reset</a>
      </div>
  </div>
  </div>
  <div class="col-md-12 col-lg-12 col-xs-12 titlediv">Home > Admin > {{selectedAdminOpt}}</div>

<div class="col-md-12 col-lg-12 col-xs-12 setupBtnDiv">
  <div class="col-md-2 col-lg-2 col-xs-3 setupBtnRemovePadding" style="width: 22%;">
    <button class="setupBtns" (click)="[selectedAdminOpt='Users']" [ngClass]="(selectedAdminOpt=='Users')?'activeBtnSetup':null" style="cursor: pointer;">Users</button>
  </div>
   <div class="col-md-2 col-lg-2 col-xs-3 setupBtnRemovePadding" style="width: 22%;">
    <button class="setupBtns" (click)="[selectedAdminOpt='Role']"  [ngClass]="(selectedAdminOpt=='Role')?'activeBtnSetup':null" style="cursor: pointer;">Role</button>
  </div>
  
   <div class="col-md-2 col-lg-2 col-xs-3 setupBtnRemovePadding" style="width: 22%;">
    <button class="setupBtns" (click)="[selectedAdminOpt='Screens']"  [ngClass]="(selectedAdminOpt=='Screens')?'activeBtnSetup':null" style="cursor: pointer;">Screens</button>
  </div>  
<!--Users-->
<div class="setupDiv" *ngIf="selectedAdminOpt=='Users'">
  <div class="setupTableDiv">
  <table class="setupGroupsTable" >
    <tr class="setupGroupsTableHeader commonGreenColor">
      <td class="setupUserTableCol">User</td>
      <td class="setupUserTableCol">First Name</td>
      <td class="setupUserTableCol">Last Name</td>
      <td class="setupUserTableCol">Role</td>
      <td >Actions</td>
    </tr>
    <tr *ngFor="let data of allUsers;" class="setupUserTableData">
        <th class="setupUserPadding">{{data.user_Id}}</th>
        <th class="setupUserPadding">{{data.first_Name}}</th>
        <th class="setupUserPadding">{{data.last_Name}}</th>
        <th class="setupUserPadding">{{data.role_Name}}</th>
        <th class="setupUserPadding" style="color: blue;text-decoration: underline;" ><span (click)="updateUser(data.user_Id)" style="cursor: pointer;">Update</span><span style="margin-left: 2vh;" (click)="openDelete(data.user_Id)" ><a>Delete</a></span>
          <span style="margin-left: 2vh;" (click)="passReset(data.user_Id)" ><a>Password Reset</a></span></th>
      </tr>
  </table>
</div>
  <div class="lowerDiv1">
    <!-- <button  class="addGroupBtn commonButtonColor"  (click)="changePassword()">change pass</button> -->
    <button  class="addGroupBtn commonButtonColor"  (click)="openUseModal()">Add User</button>
  </div>
</div>
<!--Role-->
<div class="setupDiv" *ngIf="selectedAdminOpt=='Role'">
  <div class="col-md-12 col-lg-12 col-xs-12 searchinputdiv11 changeInXs">
    <div class="center">

    <div class="col-md-6 col-lg-1 firAnalyticsDiv2 change1" [ngStyle]="{'font-weight': 'bold' }">Role:</div>
  <div class="col-md-6 col-lg-3 firAnalyticsDiv31">
    <mat-select class="firAnalyticsSelect4" style="margin-top: 0vh;" [(ngModel)]="selectedRole"  placeholder="Select Role"  (selectionChange)="changeClient($event.value)">
      <mat-option *ngFor="let data of role" [value]="data.role_Id">
        {{data.role_Name}}
      </mat-option>
    </mat-select>
  

    <!-- <mat-select class="firAnalyticsSelect4"   placeholder="Select Language"  (selectionChange)="changeModel($event.value)">
      <mat-option *ngFor="let data of allLanguages" [value]="data.language_Code">
        {{data.language_Name}}
      </mat-option>

  </mat-select> -->
</div>
</div>
  </div>
  <!-- <div id="role" style="  margin: 49px 19px 107px 380px;">
  <div class="col-md-1 hidden-xs hidden-sm " style="font-weight: bold; margin-top: 0.5vh; font-size: 15px;" >Role </div>
      <div class="col-md-5 hidden-xs hidden-sm" >
        
        <mat-select class="firAnalyticsSelect4" style="margin-top: 0vh;" [(ngModel)]="selectedState"  placeholder="Select Role"  (selectionChange)="changeClient($event.value)">
          <mat-option *ngFor="let data of role" [value]="data.role_Id">
            {{data.role_Name}}
          </mat-option>
        </mat-select>
         <input type="text" value="Admin" size="28" > 
      </div>
  </div> -->
  <div class="setupTableDivRole">
  <table class="setupGroupsTable">
    <tr class="setupGroupsTableHeader commonGreenColor">
      <td class="setupUserTableCol">Screen</td>
      <td class="setupUserTableCol">Access Allowed</td>
      
    </tr>
    <tr *ngFor="let data of roleFeature;" class="setupUserTableData">
        <th class="setupUserPadding">{{data.feature_Name}}</th>
        <th class="setupUserPadding" *ngIf="data.access_Allowed=='Y'" (click)="setAccess_Allowed(data.role_Id,data.feature_Id,data.access_Allowed)"><a>✔</a></th>
        <th class="setupUserPadding" *ngIf="data.access_Allowed=='N'" (click)="setAccess_Allowed(data.role_Id,data.feature_Id,data.access_Allowed)"><a>✖</a></th>
      
      </tr>
  </table>
  <div class="col-md-12 col-lg-12"  >
    {{messageInRole}}
    </div>
</div>

  <div class="lowerDiv1">
    <button  class="addGroupBtn commonButtonColor" (click)="addRole()">Add Role</button>
  </div>
</div>
<!--Screens-->


<div class="setupDiv" *ngIf="selectedAdminOpt=='Screens'">
  <div class="setupTableDivRole">
    <table class="setupGroupsTable">
      <tr class="setupGroupsTableHeader commonGreenColor">
        <td class="setupUserTableCol">List of Screen/Feature/Function</td>
        <td class="setupUserTableCol">Screen Sequence Number</td>
        
      </tr>
      <tr *ngFor="let data of featureData;" class="setupUserTableData">
          <th class="setupUserPadding">{{data.feature_Name}}</th>
          <th class="setupUserPadding">{{data.Screen_Sequence_Number}}</th>
        </tr>
    </table>
  </div>
<!--  
  <div class="setupTableDiv">
  <table class="setupGroupsTable" >
    <tr class="setupGroupsTableHeader commonGreenColor">
      <td class="setupUserTableCol">List of Screen/Feature/Function</td>      
    </tr>
    <tr *ngFor="let data of featureData;" class="setupUserTableData">
        <th class="setupUserPadding">{{data.feature_Name}}</th>
      </tr>
  </table>
</div> -->
  <!-- <div class="lowerDiv1">
    <button  class="addGroupBtn commonButtonColor" >Add Screens</button>
  </div> -->
</div>
<!--Classification Hierarchy-->
<!-- <div class="setupDiv" *ngIf="selectedAdminOpt=='Classification Hierarchy'">
  <div class="col-md-12 col-lg-12" style="height:3vh;line-height: 3vh;">
    <div class="col-md-1 col-lg-1" style="text-decoration: underline;">IPC</div>
    <div class="col-md-1 col-lg-1" style="color: #4474c4;text-decoration: underline;">SSL</div>
  </div>
  <div class="setupTableDiv">
  <table class="setupGroupsTable" >
    <thead>
    <tr class="setupGroupsTableHeader commonGreenColor">
      <td class="setupUserTableCol" style="width:30%">Grouping</td>
      <td style="width:70%">Category / Sub-ategory / Sub-sub-category</td>
    </tr>
  </thead>
  <tbody>
    <tr class="setupUserTableData">
      <th class="setupUserPadding">Offences of Human Body</th>
      <th class="setupUserPadding" style="padding-right: 1vw;">
          <ul style="list-style: none;margin: 0;padding: 0;">
          <li>Murder<span style="float: right;">
          <a style="padding: 10px;">Add Child</a><a>Delete</a>
          </span>
          </li>
        </ul>
      </th>
    </tr>
    <tr class="setupUserTableData">
      <th class="setupUserPadding">Offences of Human Body</th>
      <th class="setupUserPadding" style="padding-right: 1vw;">
          <ul style="list-style: none;margin: 0;padding: 0;">
          <li>Culpable Homicide not amounting to Murder<span style="float: right;">
          <a style="padding: 10px;">Add Child</a><a>Delete</a>
          </span>
          </li>
        </ul>
      </th>
    </tr>
    <tr class="setupUserTableData">
        <th class="setupUserPadding">Offences of Human Body</th>
        <th class="setupUserPadding" style="padding-right: 1vw;padding-top:1vh;">
            <ul class="tree" style="list-style: none;margin: 0;padding: 0;">
            <li>Causing Death by Negligence<span style="float: right;border-left: none;">
              <a style="padding: 10px;">Add Child</a><a>Delete</a>
              </span>
              <ul>
                <li>Deaths due to Negligence relating to Road Accidents<span style="float: right;">
                  <a style="padding: 10px;">Add Child</a><a>Delete</a>
                  </span>
                  <ul>
                <li class="last">Hit and Run<span style="float: right;">
                  <a style="padding: 10px;">Add Child</a><a>Delete</a>
                  </span></li>
                  <li class="last">Other Accidents<span style="float: right;">
                    <a style="padding: 10px;">Add Child</a><a>Delete</a>
                    </span></li>
                </ul>
                  </li>
                  <li>Deaths due to Negligence relating to Rail Accidents<span style="float: right;">
                    <a style="padding: 10px;">Add Child</a><a>Delete</a>
                    </span>
                    </li>
                    <li>Deaths due to Medical Negligence<span style="float: right;">
                      <a style="padding: 10px;">Add Child</a><a>Delete</a>
                      </span>
                      </li>
                      <li>Deaths due to Negligence of Civic Bodies<span style="float: right;">
                        <a style="padding: 10px;">Add Child</a><a>Delete</a>
                        </span>
                        </li>
                        <li>Deaths due to other Negligence<span style="float: right;">
                          <a style="padding: 10px;">Add Child</a><a>Delete</a>
                          </span>
                          </li>
                  
              </ul>
              </li>
          </ul>
        </th>
        
      </tr>
      <tr class="setupUserTableData">
        <th class="setupUserPadding">Offences of Human Body</th>
        <th class="setupUserPadding" style="padding-right: 1vw;">
            <ul style="list-style: none;margin: 0;padding: 0;">
            <li>Dowry Deaths<span style="float: right;">
            <a style="padding: 10px;">Add Child</a><a>Delete</a>
            </span>
            </li>
          </ul>
        </th>
      </tr>
      <tr class="setupUserTableData">
        <th class="setupUserPadding">Offences of Human Body</th>
        <th class="setupUserPadding" style="padding-right: 1vw;">
            <ul style="list-style: none;margin: 0;padding: 0;">
            <li>Abetment of suicide<span style="float: right;">
            <a style="padding: 10px;">Add Child</a><a>Delete</a>
            </span>
            </li>
          </ul>
        </th>
      </tr>
      <tr class="setupUserTableData">
        <th class="setupUserPadding">Offences of Human Body</th>
        <th class="setupUserPadding" style="padding-right: 1vw;">
            <ul style="list-style: none;margin: 0;padding: 0;">
            <li>Attempt to Commit Murder<span style="float: right;">
            <a style="padding: 10px;">Add Child</a><a>Delete</a>
            </span>
            </li>
          </ul>
        </th>
      </tr>
    </tbody>
  </table>
</div>
  <div class="lowerDiv2">
    <button  class="addGroupBtn commonButtonColor">Add Category</button>
  </div>

</div> -->
<!--Crime Dictionary-->
  <!-- <div class="setupDiv" *ngIf="selectedAdminOpt=='Crime Dictionary'">
    <div class="col-md-12 col-lg-12" style="height:3vh;line-height: 3vh;color: #4474c4;">
      <div class="col-md-1 col-lg-1" style="width: 11.1%;text-decoration:underline;cursor:pointer;color:black">English</div>
      <div class="col-md-1 col-lg-1" style="width: 11.1%;text-decoration:underline;cursor:pointer;">Hindi</div>
      <div class="col-md-1 col-lg-1" style="width: 11.1%;text-decoration:underline;cursor:pointer;">Bengali</div>
      <div class="col-md-1 col-lg-1" style="width: 11.1%;text-decoration:underline;cursor:pointer;">Gujrati</div>
      <div class="col-md-1 col-lg-1" style="width: 11.1%;text-decoration:underline;cursor:pointer;">Kannada</div>
      <div class="col-md-1 col-lg-1" style="width: 11.1%;text-decoration:underline;cursor:pointer;">Malayalam</div>
      <div class="col-md-1 col-lg-1" style="width: 11.1%;text-decoration:underline;cursor:pointer;">Punjabi</div>
      <div class="col-md-1 col-lg-1" style="width: 11.1%;text-decoration:underline;cursor:pointer;">Tamil</div>
      <div class="col-md-1 col-lg-1" style="width: 11.1%;text-decoration:underline;cursor:pointer;">telugu</div>
    </div>
    <div class="setupTableDiv">
    <table class="setupGroupsTable" >
      <thead>
      <tr class="setupGroupsTableHeader commonGreenColor">
        <th class="setupUserTableCol" style="width:30%">Word / Phrase</th>
        <th style="width:70%">Synonyms / Similiar Phrases</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of adminCrimeDictionary;" class="setupUserTableData">
          <td class="setupUserPadding">{{data.word}}</td>
          <td class="setupUserPadding">{{data.synonym}}</td>
          
        </tr>
      </tbody>
    </table>
  </div>
    <div class="lowerDiv2">
      <button class="firAnalyticsAnalyzeButton commonButtonColor">Upload</button>
         <button class="firAnalyticsDownloadButton commonButtonColor">Add</button>
    </div>
  </div> -->
</div>
</div>

<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Modal Header</h4>
      </div>
      <div class="modal-body">
        <p>Some text in the modal.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</div>
</body>
<body *ngIf="!loggedInUser">
  <div style="text-align: center;">Login to continue.</div>
</body>
</html>

